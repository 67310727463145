import * as React from "react";

import { Button, Layout } from "@ui-kitten/components";
import { StyleSheet } from "react-native";

import { darkGrey, primary, white } from "app/styles/theme";
import { ThemeContext, type ThemeContextProps } from "app/utils/context";
import Gallery from "../images/Gallery";
import Scroll from "../scroll/Scroll";
import AboutMe from "../texts/AboutMe";
import LookingFor from "../texts/LookingFor";
import NamePlate from "../texts/NamePlate";

const Profile: React.FC<any> = (props) => {
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;
  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: themes === "light" ? white : darkGrey,
      })}
    >
      <Scroll>
        <NamePlate {...props} edit />
        <Gallery {...props} edit />
        <AboutMe {...props} edit />
        <LookingFor {...props} edit />
        {!!props?.edit && (
          <Button onPress={() => props.navigation.navigate("Age Setup")}>
            Next
          </Button>
        )}
      </Scroll>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",

    borderTopEndRadius: 40,
    borderTopStartRadius: 40,
    flex: 1,
    justifyContent: "center",
    overflow: "hidden",
    width: "100%",
  },
  icon: {
    color: primary,
    height: 32,
    width: 32,
  },
  input: {
    width: "100%",
  },
  row: {
    flexDirection: "row",
  },
  scrollView: {
    flexGrow: 1,
    height: "100%",
    width: "100%",
  },
});

export default Profile;
