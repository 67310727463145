import * as React from "react";

import Loading from "app/components/loaders/Spinner";
import { AuthContext, type AuthContextProps } from "app/utils/context";

const RedirectHome: React.FC<any> = ({ navigation }) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;

  React.useLayoutEffect(() => {
    setRoute("Home");
    setTimeout(() => {
      navigation.navigate("Home");
    }, 1000);
  }, [setRoute, navigation]);

  return <Loading />;
};

export default RedirectHome;
