import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Button, Card, Layout, Text } from "@ui-kitten/components";
import axios from "axios";
import { StyleSheet, View } from "react-native";

import { darkGrey, primary, white } from "app/styles/theme";
import { HOSTNAME } from "app/utils/ajax";
import { ThemeContext, type ThemeContextProps } from "app/utils/context";
import Scroll from "../scroll/Scroll";

const Membership: React.FC<any> = (props) => {
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;
  const [selected, setSelected] = React.useState(0);

  const updateUser = async () => {
    const token = await AsyncStorage.getItem("token");
    await axios.put(`${HOSTNAME}/api/v1/renew_users`, null, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  };

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: themes === "light" ? white : darkGrey,
      })}
    >
      <Scroll>
        <View style={styles.row}>
          <Card
            style={Object.assign(
              {},
              styles.noPadding,
              props.edit && selected === 1 ? styles.outline : {}
            )}
            onPress={() => {
              setSelected(1);
            }}
          >
            <Text category="h2">One Month</Text>
            <Text category="h1">$9.99</Text>
            <Text category="p">per month</Text>
          </Card>
          <Card
            style={Object.assign(
              {},
              styles.noPadding,
              props.edit && selected === 2 ? styles.outline : {}
            )}
            onPress={() => {
              setSelected(2);
            }}
          >
            <Text category="h2">Three Months</Text>
            <Text category="h1">$24.99</Text>
            <Text category="p">per quarter</Text>
          </Card>
          <Card
            style={Object.assign(
              {},
              styles.noPadding,
              props.edit && selected === 3 ? styles.outline : {}
            )}
            onPress={() => {
              setSelected(3);
            }}
          >
            <Text category="h2">Six Months</Text>
            <Text category="h1">$44.99</Text>
            <Text category="p">per half year</Text>
          </Card>
        </View>
        {!!props.edit && (
          <Button
            onPress={async () =>
              await updateUser().then(() => props.navigation.navigate("Home"))
            }
          >
            Next
          </Button>
        )}
      </Scroll>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
  noPadding: {
    height: "100%",
    margin: 0,
    padding: 0,
  },
  outline: {
    borderColor: primary,
    borderWidth: 5,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
});

export default Membership;
