import * as React from "react";

import { View, Text, StyleSheet } from "react-native";

import { red } from "../../styles/theme";

interface Props {
  message?: string
  color?: string
}

const ErrorMessage: React.FC<Props> = ({ message = "", color = red }) => (
  <View style={styles.container}>
    <Text style={Object.assign({}, { color }, styles.message)}>{message}</Text>
  </View>
);

const styles = StyleSheet.create({
  container: {
    alignContent: "center",

    flex: 1,
    justifyContent: "center",
    marginVertical: 10,
    paddingVertical: 10,
  },
  message: {
    color: red,
    textAlign: "center",
  },
});

export default ErrorMessage;
