import * as React from "react";

import Svg, { G, Path } from "react-native-svg";

const Pending = (props: any) => {
  return (
    <Svg {...props} viewBox="0 0 24 24">
      <G data-name="Layer 2">
        <G data-name="clock">
          <Path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm5 11h-4V7a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1h5a1 1 0 0 0 0-2z" />
        </G>
      </G>
    </Svg>
  );
};

export default Pending;
