import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Layout, Input } from "@ui-kitten/components";
import axios from "axios";
import {
  StyleSheet,
  View,
  Pressable,
  Text,
  useWindowDimensions,
  Platform,
  KeyboardAvoidingView,
} from "react-native";

import ErrorMessage from "app/components/errors/Message";
import Loader from "app/components/loaders/Spinner";
import {
  darkGrey,
  white,
  black,
  grey,
  maxHeight,
  maxWidth,
  primary,
  red,
} from "app/styles/theme";
import { HOSTNAME, redirectToHomepageWhenExpired } from "app/utils/ajax";
import {
  AuthContext,
  ThemeContext,
  type ThemeContextProps,
  type AuthContextProps,
} from "app/utils/context";

const ForgottenTwoWayAuth: React.FC<any> = ({ navigation, route }) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [twoWayAuth, setTwoWayAuth] = React.useState("");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const layout = useWindowDimensions();
  const width = layout.width > maxWidth ? maxWidth : layout.width;
  const height = layout.height > maxHeight ? maxHeight : layout.height;

  const title = { fontSize: width * 0.1 };
  const buttonText = {
    fontSize: width * (Platform.OS === "web" ? 0.025 : 0.05),
  };
  const subContainer = {
    width: width * 0.8,
    height: height * 0.8,
  };

  const resetErrorMessage = (): void => {
    setTimeout(() => {
      setError("");
    }, 3000);
  };

  const sendCode = async (phone: string): Promise<void> => {
    setLoading(true);

    try {
      const headers = { "Content-Type": "application/json" };
      const res = await axios.post(
        `${HOSTNAME}/api/v1/send_two_way_auth`,
        { phone_number: phone },
        { headers }
      );

      if (res?.data?.error) {
        setError(res?.data?.error);
        resetErrorMessage();
      } else {
        setError("");
      }
    } catch (error: any) {
      setError(error?.response?.data?.error);
      resetErrorMessage();
      await redirectToHomepageWhenExpired(error, navigation, setRoute);
    }

    setLoading(false);
  };

  const onSubmit = async (): Promise<void> => {
    setLoading(true);

    try {
      const headers = { "Content-Type": "application/json" };
      const res = await axios.post(
        `${HOSTNAME}/api/v1/two_way_auth`,
        { two_auth_code: twoWayAuth },
        { headers }
      );

      if (res?.data?.error) {
        setError(res?.data?.error);
        throw new Error(res?.data);
      } else {
        setError("");
      }
      if (res?.data?.access_token) {
        await AsyncStorage.setItem("token", String(res?.data?.access_token));
        await AsyncStorage.setItem(
          "phoneNumber",
          String(res?.data?.phone_number)
        );
        await AsyncStorage.setItem("forgottenPassword", "true");
        setRoute("Reset Password");
        navigation.navigate("Reset Password");
      }
    } catch (error: any) {
      setError(error?.response?.data?.error);
      resetErrorMessage();
      await redirectToHomepageWhenExpired(error, navigation, setRoute);
    }

    setLoading(false);
  };

  React.useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setLoading(true);

      try {
        const phone = await AsyncStorage.getItem("phoneNumber");
        if (phone) {
          setPhoneNumber(phone);
        } else {
          setRoute("Forgotten Password");
          navigation.navigate("Forgotten Password");
        }
        setError("");
      } catch (error: any) {
        setError(error?.response?.data?.error);
        resetErrorMessage();
        await redirectToHomepageWhenExpired(error, navigation, setRoute);
      }

      setLoading(false);
    };

    setRoute(route?.name);
    fetchData().catch(console.error);
  }, [setRoute, route?.name, navigation]);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: themes === "light" ? white : darkGrey,
      })}
    >
      <View style={Object.assign({}, styles.subContainer, subContainer)}>
        <KeyboardAvoidingView>
          <View style={styles.titleContainer}>
            <Text style={Object.assign({}, title, styles.title)}>
              Forgotten Password Two Way Auth
            </Text>
          </View>
          <View style={styles.mainSection}>
            <View style={styles.main}>
              <Input
                style={Object.assign({}, styles.textField, {
                  color: themes === "light" ? darkGrey : white,
                })}
                onChangeText={(code) => {
                  setTwoWayAuth(code);
                }}
                keyboardType="numeric"
                maxLength={6}
              />
            </View>
          </View>
          {loading && <Loader />}
          <View style={styles.section}>
            <Pressable
              style={Object.assign({}, styles.text, {
                color: themes === "light" ? darkGrey : white,
              })}
              disabled={loading}
              onPress={() => {
                sendCode(phoneNumber).catch(console.error);
              }}
            >
              <Text
                style={Object.assign({}, styles.text, {
                  color: themes === "light" ? darkGrey : white,
                })}
              >
                Resend Code
              </Text>
            </Pressable>
          </View>
          {error?.length > 0 && <ErrorMessage message={error} color={red} />}
          <Pressable
            disabled={loading}
            style={Object.assign({}, styles.buttonContainer, {
              color: themes === "light" ? darkGrey : white,
            })}
            onPress={() => {
              onSubmit().catch(console.error);
            }}
          >
            <View>
              <Text
                style={Object.assign(
                  {},
                  buttonText,
                  Object.assign({}, styles.buttonText, {
                    color: themes === "light" ? darkGrey : white,
                  })
                )}
              >
                Continue
              </Text>
            </View>
          </Pressable>
        </KeyboardAvoidingView>
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    alignItems: "center",
    backgroundColor: primary,
    borderRadius: 50,

    display: "flex",
    height: Platform.OS === "web" ? "5%" : "7.5%",
    justifyContent: "center",
    margin: 0,
    padding: 0,
    width: "80%",
  },
  buttonText: {
    alignItems: "center",
    backgroundColor: primary,

    display: "flex",
    justifyContent: "center",
    margin: 0,
    padding: 0,
  },
  container: {
    alignItems: "center",

    display: "flex",
    flex: 1,
    height: "100%",
    justifyContent: "center",
    margin: 0,
    padding: 0,
    width: "100%",
  },
  main: {
    alignContent: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
  },
  mainSection: {
    alignContent: "center",
    display: "flex",
    flexDirection: "column",
    marginVertical: 20,
    justifyContent: "center",
    width: "80%",
  },
  section: {
    alignContent: "center",
    display: "flex",
    flexDirection: "column",
    height: "10%",
    justifyContent: "center",
  },
  subContainer: {
    alignItems: "center",

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: 0,
    padding: 0,
  },
  text: {
    color: primary,
    display: "flex",
    width: "100%",
  },
  textField: {
    alignItems: "center",
    borderColor: grey,
    borderRadius: 50,
    borderWidth: 2,
    color: black,
    display: "flex",
    height: 50,
    justifyContent: "center",
    paddingLeft: 20,
    paddingRight: 20,
    width: "100%",
  },
  title: {
    alignItems: "center",
    color: primary,
    display: "flex",
    fontFamily: "Futura",
    height: Platform.OS === "web" ? "5%" : "auto",
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
  },
  titleContainer: {
    alignItems: "center",

    display: "flex",
    height: Platform.OS === "web" ? "5%" : "auto",
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
  },
});

export default ForgottenTwoWayAuth;
