import * as React from "react";

export const AuthContext = React.createContext({});

export interface AuthContextProps {
  route: string
  setRoute: React.Dispatch<React.SetStateAction<string>>
}

export const ThemeContext = React.createContext({});

export interface ThemeContextProps {
  themes: string
  setThemes: React.Dispatch<React.SetStateAction<string>>
}
