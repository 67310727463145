import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Layout, Card, Input, Button, Text } from "@ui-kitten/components";
import axios from "axios";
import { StyleSheet } from "react-native";

import { HOSTNAME } from "app/utils/ajax";
import {
  AuthContext,
  ThemeContext,
  type ThemeContextProps,
  type AuthContextProps,
} from "app/utils/context";

const LookingFor: React.FC<any> = ({ navigation, route, edit = false }) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;

  const fetchProfile = async () => {
    try {
      const token = await AsyncStorage.getItem("token");
      const id = route?.params?.user_match_id || route?.params?.id;
      const response = await axios.get(
        `${HOSTNAME}/api/v1/user_profile${id ? "/" + id : ""}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = response?.data;
      const data = Array.isArray(res?.data) ? res?.data[0] : res?.data;
      setLookingFor(data?.looking_for);
    } catch (error) {
      console.error(error);
    }
  };

  const setLookingForText = async () => {
    try {
      const token = await AsyncStorage.getItem("token");
      const response = await axios.put(
        `${HOSTNAME}/api/v1/user_profile`,
        { looking_for },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = response?.data;
      setLookingFor(res?.data[0]?.looking_for);
    } catch (error) {
      console.error(error);
    }
  };

  const [looking_for, setLookingFor] = React.useState("");

  React.useLayoutEffect(() => {
    setRoute(route?.name);
  }, [setRoute, route?.name]);

  React.useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <Layout>
      <Card>
        {edit && (
          <Input
            placeholder="Looking For"
            style={styles.input}
            value={looking_for}
            size="large"
            onChangeText={(text) => {
              setLookingFor(text);
            }}
          />
        )}
        {!edit && <Text category="p">{looking_for}</Text>}
        {edit && (
          <Button
            onPress={async () => {
              await setLookingForText();
            }}
          >
            Save
          </Button>
        )}
      </Card>
    </Layout>
  );
};

const styles = StyleSheet.create({
  input: {
    width: "100%",
  },
});

export default LookingFor;
