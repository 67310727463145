import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Link, useLinkTo } from "@react-navigation/native";
import {
  Button,
  Card,
  Layout,
  Modal,
  Text,
  CheckBox,
} from "@ui-kitten/components";
import axios from "axios";
import moment from "moment";
import { StyleSheet, View } from "react-native";
import { TimePickerModal, DatePickerModal } from "react-native-paper-dates";
import { Svg, Path, Circle, G, Rect } from "react-native-svg";

import { darkGrey, white } from "app/styles/theme";
import { HOSTNAME } from "app/utils/ajax";
import { ThemeContext, type ThemeContextProps } from "app/utils/context";
import { formatDateTime, getTimezone } from "app/utils/dates";
import Scroll from "../scroll/Scroll";

const AppointmentCalendar: React.FC<any> = ({ route, navigation }) => {
  const { themes }: ThemeContextProps = React.useContext(
    ThemeContext
  ) as ThemeContextProps;

  const [timeModal, setTimeModal]: any = React.useState(false);
  const [startTimeModal, setStartTimeModal]: any = React.useState(false);
  const [endTimeModal, setEndTimeModal]: any = React.useState(false);
  const [dateModal, setDateModal]: any = React.useState(false);
  const [isRepeating, setIsRepeating]: any = React.useState(false);
  const [startDate, setStartDate]: any = React.useState(moment().toDate());
  const [endDate, setEndDate]: any = React.useState(moment().toDate());
  const [date, setDate]: any = React.useState(moment().toDate());
  const [items, setItems]: any = React.useState([]);
  const [dates, setDates]: any = React.useState([]);
  const [userId, setUserId]: any = React.useState(null);

  const deleteTime = async (id: any) => {
    try {
      const token = await AsyncStorage.getItem("token");
      await axios.delete(`${HOSTNAME}/api/v1/user_available_schedules/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      await fetchDateTime();
    } catch (error) {
      console.error(error);
    }
  };

  const fetchVirtualDates = async () => {
    try {
      const userid = await AsyncStorage.getItem("userId");
      const token = await AsyncStorage.getItem("token");
      const userId = window?.location?.search?.split("=")[1] || userid;
      const { data } = await axios.get(
        `${HOSTNAME}/api/v1/virtual_dates${
          route?.params?.userId || userId
            ? `?match_user_id=${route?.params?.userId || userId}`
            : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDates(data?.data || []);
    } catch (error) {
      console.error(error);
    }
  };

  const createVirtualDate = async (appointment: any) => {
    try {
      const token = await AsyncStorage.getItem("token");
      await axios.post(
        `${HOSTNAME}/api/v1/virtual_dates`,
        {
          start_time: moment(appointment?.startDate || new Date())
            .tz(getTimezone())
            .toISOString(),
          end_time: moment(appointment?.endDate || new Date())
            .tz(getTimezone())
            .toISOString(),
          datestring: moment(appointment?.startDate || new Date())
            .tz(getTimezone())
            .toISOString(),
          match_id:
            route?.params?.userId || window?.location?.search?.split("=")[1],
          user_accepted: true,
          match_accepted: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await fetchDateTime();
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDateTime = async () => {
    try {
      const userId = await AsyncStorage.getItem("userId");
      setUserId(Number(userId));

      const token = await AsyncStorage.getItem("token");
      const { data } = await axios.get(
        `${HOSTNAME}/api/v1/user_available_schedules${
          route?.params?.userId || userId
            ? `?match_id=${route?.params?.userId || userId}`
            : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setItems(
        (data?.data || [])
          .filter((item: any) => item?.start_time && item?.end_time)
          .map((item: any) => {
            const start = item?.start_time?.match(/\{/g)
              ? moment(item?.datestring).set(JSON.parse(item?.start_time))
              : moment(item?.start_time).tz(getTimezone());
            const end = item?.end_time?.match(/\{/g)
              ? moment(item?.datestring).set(JSON.parse(item?.end_time))
              : moment(item?.end_time).tz(getTimezone());

            return {
              id: item?.id,
              title: "Available",
              startDate: start.toDate(),
              endDate: end.toDate(),
              date: moment(item?.datestring).toDate(),
              showButtons:
                !(item?.match_accepted && item?.user_accepted) &&
                item?.user_id !== Number(userId),
            };
          })
          .sort((a: any, b: any) => b.id - a.id)
      );
    } catch (error) {
      console.error(error);
    }
  };

  const setDateTime = async () => {
    try {
      const token = await AsyncStorage.getItem("token");
      await axios.post(
        `${HOSTNAME}/api/v1/user_available_schedules`,
        {
          start_time: moment(startDate || new Date())
            .tz(getTimezone())
            .toISOString(),
          end_time: moment(endDate || new Date())
            .tz(getTimezone())
            .toISOString(),
          is_repeat: isRepeating,
          datestring: moment(date || new Date())
            .tz(getTimezone())
            .toISOString(),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await fetchDateTime();
    } catch (error) {
      console.error(error);
    }
  };

  const refetch = async () => {
    try {
      await fetchDateTime();
      await fetchVirtualDates();
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    refetch();
  }, [date]);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: themes === "light" ? white : darkGrey,
      })}
    >
      <Scroll style={{ width: "100%" }}>
        <View style={styles.titleContainer}>
          <View
            style={
              Object.assign({}, styles.buttonContainer, {
                color: themes === "light" ? darkGrey : white,
              }) as any
            }
          >
            <Button onPress={() => setDateModal(true)}>
              <Svg
                fill={themes === "light" ? white : darkGrey}
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
              >
                <Path
                  fill="currentColor"
                  d="M18 4h-1V3a1 1 0 0 0-2 0v1H9V3a1 1 0 0 0-2 0v1H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3M6 6h1v1a1 1 0 0 0 2 0V6h6v1a1 1 0 0 0 2 0V6h1a1 1 0 0 1 1 1v4H5V7a1 1 0 0 1 1-1m12 14H6a1 1 0 0 1-1-1v-6h14v6a1 1 0 0 1-1 1"
                />
                <Circle cx="8" cy="16" r="1" fill="currentColor" />
                <Path
                  fill="currentColor"
                  d="M16 15h-4a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2"
                />
              </Svg>
            </Button>
            <Button onPress={() => setTimeModal(true)}>
              <Svg
                fill={themes === "light" ? white : darkGrey}
                viewBox="0 0 24 24"
              >
                <G data-name="Layer 2">
                  <G data-name="clock">
                    <Rect
                      width="24"
                      height="24"
                      transform="rotate(180 12 12)"
                      opacity="0"
                    />
                    <Path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z" />
                    <Path d="M16 11h-3V8a1 1 0 0 0-2 0v4a1 1 0 0 0 1 1h4a1 1 0 0 0 0-2z" />
                  </G>
                </G>
              </Svg>
            </Button>
          </View>
          <Text category="h1">Appointment Calendar</Text>
          <Button
            onPress={async () => {
              await refetch();
            }}
          >
            ⟳
          </Button>
        </View>
        <Layout style={styles.section}>
          <Text>Confirmed Dates</Text>
          {dates?.map((virtualDate: any) => (
            <Link
              key={virtualDate?.id}
              to={{
                screen: "Virtual Dates",
                params: {
                  userCallerId:
                    route?.params?.userId === virtualDate?.user_id
                      ? virtualDate?.user_caller_id
                      : virtualDate?.match_caller_id,
                  matchCallerId:
                    route?.params?.userId === virtualDate?.user_id
                      ? virtualDate?.match_caller_id
                      : virtualDate?.user_caller_id,
                },
              }}
            >
              <Card style={styles.card}>
                <Text>
                  {moment(virtualDate?.start_time)?.format("MMMM DD, YYYY")}
                  {" : "}
                  {moment(virtualDate?.start_time)?.format("hh:mm A")}
                  {" - "}
                  {moment(virtualDate?.end_time)?.format("h:mm A")}
                </Text>
                <Text>
                  Virtual Date Caller ID:{" "}
                  {virtualDate?.user_id === route?.params?.userId
                    ? virtualDate?.user_caller_id
                    : virtualDate?.match_caller_id}
                </Text>
              </Card>
            </Link>
          ))}
        </Layout>
        <Layout style={styles.section}>
          <Text>Date Proposals</Text>
          {items?.map((appointment: any) => (
            <Card key={appointment?.id} style={styles.card}>
              <Text>{appointment?.title}</Text>
              <Text>
                {moment(appointment?.startDate)?.format("MMMM DD, YYYY")}
                {" : "}
                {moment(appointment?.startDate)?.format("hh:mm A")}
                {" - "}
                {moment(appointment?.endDate)?.format("h:mm A")}
              </Text>
              {appointment?.showButtons && (
                <View style={styles.row}>
                  <Button
                    onPress={async () => {
                      await createVirtualDate(appointment);
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    onPress={async () => {
                      await deleteTime(appointment.id);
                    }}
                  >
                    Deny
                  </Button>
                </View>
              )}
            </Card>
          ))}
        </Layout>
      </Scroll>
      <Modal
        visible={timeModal}
        backdropStyle={styles.backdrop}
        onBackdropPress={() => setTimeModal(false)}
      >
        <Card>
          <Text>Set Free Time</Text>
          <Text>
            From: {formatDateTime(startDate || new Date(), "hh:mm A")}
          </Text>
          <Button onPress={() => setStartTimeModal(true)}>
            Set Start Time
          </Button>
          <TimePickerModal
            visible={startTimeModal}
            onDismiss={() => setStartTimeModal(false)}
            onConfirm={(v: any) => {
              setStartDate(v);
              setStartTimeModal(false);
            }}
          />
          <Text>To: {formatDateTime(endDate || new Date(), "hh:mm A")}</Text>
          <Button onPress={() => setEndTimeModal(true)}>Set End Time</Button>
          <TimePickerModal
            visible={endTimeModal}
            onDismiss={() => setEndTimeModal(false)}
            onConfirm={(v: any) => {
              setEndDate(v);
              setEndTimeModal(false);
            }}
          />
          <CheckBox
            checked={isRepeating}
            onChange={(v: any) => setIsRepeating(v)}
          >
            Repeating
          </CheckBox>
          <Button
            onPress={async () =>
              await setDateTime().then(() => setTimeModal(false))
            }
          >
            Save
          </Button>
          <Button onPress={() => setTimeModal(false)}>Close</Button>
        </Card>
      </Modal>
      <Modal
        visible={dateModal}
        backdropStyle={styles.backdrop}
        onBackdropPress={() => setDateModal(false)}
      >
        <Card>
          <Text>Set Date</Text>
          <Text>{formatDateTime(date || new Date(), "MMMM DD, YYYY")}</Text>
          <DatePickerModal
            visible={dateModal}
            onDismiss={() => setDateModal(false)}
            onConfirm={(v: any) => {
              setDate(moment(v).tz(getTimezone()).toDate());
              setDateModal(false);
            }}
            locale="en"
            mode="single"
            date={date}
          />
          <Button onPress={() => setDateModal(false)}>Save</Button>
        </Card>
      </Modal>
    </Layout>
  );
};

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  buttonContainer: {
    // flexDirection: "row",
  },
  card: {
    backgroundColor: white,
    flexDirection: "row",
    width: "100%",
  },
  container: {
    alignItems: "center",

    flexGrow: 1,
    justifyContent: "flex-start",
    width: "100%",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  section: {
    marginTop: 100,
    width: "100%",
  },
  table: {
    width: "100%",
  },
  titleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 50,
    position: "absolute",
    top: 0,
    width: "100%",
    zIndex: 9999,
  },
});

export default AppointmentCalendar;
