/* eslint-disable */
import * as React from "react";
import moment from "moment";
import {
  Platform,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  Keyboard,
  View,
  Text,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import {
  mediaDevices,
  RTCPeerConnection,
  RTCView,
  RTCIceCandidate,
  RTCSessionDescription,
} from "react-native-webrtc-web-shim";
import SocketIOClient from "socket.io-client";
import { HOSTNAME, WEBSOCKETS_HOSTNAME } from "../../utils/ajax";
import CallAnswer from "../icons/CallAnswer";
import CallEnd from "../icons/CallEnd";
import CameraSwitch from "../icons/CameraSwitch";
import MicOff from "../icons/MicOff";
import MicOn from "../icons/MicOn";
import VideoOff from "../icons/VideoOff";
import VideoOn from "../icons/VideoOn";
import IconContainer from "./IconContainer";
import TextInputContainer from "./TextInputContainer";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { Layout } from "@ui-kitten/components";
import ConnectFour from "../games/versus/ConnectFour";

const AudioCall = ({ route }: any) => {
  const [localStream, setlocalStream]: any = React.useState(null);
  const [remoteStream, setRemoteStream]: any = React.useState(null);
  const [type, setType]: any = React.useState("JOIN");
  const [callerId, setCallerId]: any = React.useState("");
  const [callerOtherId, setOtherCallerId]: any = React.useState("");
  const [virtual, setVirtual]: any = React.useState({});
  const [localMicOn, setlocalMicOn]: any = React.useState(true);
  const [localWebcamOn, setlocalWebcamOn]: any = React.useState(true);

  const peerConnection: any = React.useRef(
    new RTCPeerConnection({
      iceServers: [
        {
          urls: "stun:stun.l.google.com:19302",
        },
        {
          urls: "stun:stun1.l.google.com:19302",
        },
        {
          urls: "stun:stun2.l.google.com:19302",
        },
      ],
    })
  );

  const remoteRTCMessage: any = React.useRef(null);

  let MediaDevices = mediaDevices || window?.navigator?.mediaDevices;

  const fetchVirtualDate = async () => {
    try {
      const userId = await AsyncStorage.getItem("userId");
      const token = await AsyncStorage.getItem("token");
      const { data } = await axios.get(
        `${HOSTNAME}/api/v1/virtual_date?user_caller_id=${route?.params?.userCallerId}&match_caller_id=${route?.params?.matchCallerId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const date = data?.data[0];
      setVirtual(date);
      setCallerId(
        Number(date?.user_id) === Number(userId)
          ? date?.user_caller_id
          : date?.match_caller_id
      );
      setOtherCallerId(
        Number(date?.user_id) === Number(userId)
          ? date?.match_caller_id
          : date?.user_caller_id
      );
      await AsyncStorage.setItem("callerId", callerId);
    } catch (error) {
      console.error(error);
    }
  };

  const socket: any = SocketIOClient(WEBSOCKETS_HOSTNAME, {
    transports: ["websocket"],
    query: {
      callerId,
    },
  });

  const setMatch = async (accepted: boolean) => {
    try {
      const myCallerId = await AsyncStorage.getItem("callerId");
      const token = await AsyncStorage.getItem("token");
      const response = await axios.put(
        `${HOSTNAME}/api/v1/virtual_dates`,
        {
          user_caller_id: callerId,
          match_caller_id: callerOtherId,
          datestring: moment().format(),
          [myCallerId === callerId ? "user_accepted" : "match_accepted"]:
            accepted,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response?.data;
      setVirtual(data);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    fetchVirtualDate();

    const setCallerIds = async () => {
      const userCallerId = route?.params?.userCallerId;
      const matchCallerId = route?.params?.matchCallerId;

      setCallerId(userCallerId);
      setOtherCallerId(matchCallerId);
    };

    setCallerIds();

    socket.on("newCall", (data: any) => {
      remoteRTCMessage.current = data.rtcMessage;
      setOtherCallerId(data.callerId);
      setType("INCOMING_CALL");
    });

    socket.on("callAnswered", (data: any) => {
      remoteRTCMessage.current = data.rtcMessage;
      peerConnection.current.setRemoteDescription(
        new RTCSessionDescription(remoteRTCMessage.current)
      );
      setType("WEBRTC_ROOM");
    });

    socket.on("ICEcandidate", async (data: any) => {
      const message = data.rtcMessage;

      if (peerConnection.current) {
        peerConnection.current.setRemoteDescription(
          await peerConnection.current.createOffer()
        );
        peerConnection.current
          .addIceCandidate(
            new RTCIceCandidate({
              candidate: message.candidate,
              sdpMid: message.id,
              sdpMLineIndex: message.label,
            })
          )
          .then((data: any) => {
            setType("WEBRTC_ROOM");
            socket.emit("answerCall", {
              callerId: callerOtherId,
              rtcMessage: peerConnection.current.localDescription,
            });
          })
          .catch((err: any) => {
            console.error("Error", err);
          });
      }
    });

    const isFront = true;

    MediaDevices?.enumerateDevices()?.then((sourceInfos: any) => {
      let videoSourceId;
      for (let i = 0; i < sourceInfos.length; i++) {
        const sourceInfo = sourceInfos[i];
        if (
          sourceInfo.kind == "videoinput" &&
          sourceInfo.facing == (isFront ? "user" : "environment")
        ) {
          videoSourceId = sourceInfo.deviceId;
        }
      }

      MediaDevices?.getUserMedia({
        audio: true,
      })
        .then((stream: any) => {
          // Got stream!
          setlocalStream(stream);
          // setup stream listening
          peerConnection.current.addStream(stream);
        })
        .catch((error: any) => {
          console.error(error);
        });
    });

    peerConnection.current.onaddstream = (event: any) => {
      setRemoteStream(event.stream);
    };

    // Setup ice handling
    peerConnection.current.onicecandidate = (event: any) => {
      if (event.candidate) {
        sendICEcandidate({
          calleeId: callerOtherId,
          rtcMessage: {
            label: event.candidate.sdpMLineIndex,
            id: event.candidate.sdpMid,
            candidate: event.candidate.candidate,
          },
        });
      } else {
        console.log("End of candidates.");
      }
    };

    return () => {
      socket.off("newCall");
      socket.off("callAnswered");
      socket.off("ICEcandidate");
    };
  }, []);

  function sendICEcandidate(data: any) {
    socket.emit("ICEcandidate", data);
  }

  async function processCall() {
    const sessionDescription = await peerConnection.current.createOffer();
    await peerConnection.current.setLocalDescription(sessionDescription);
    sendCall({
      calleeId: callerOtherId,
      rtcMessage: sessionDescription,
    });
  }

  async function processAccept() {
    peerConnection.current.setRemoteDescription(
      new RTCSessionDescription(remoteRTCMessage.current)
    );
    const sessionDescription = await peerConnection.current.createAnswer();
    await peerConnection.current.setLocalDescription(sessionDescription);
    answerCall({
      callerId: callerOtherId,
      rtcMessage: sessionDescription,
    });
  }

  function answerCall(data: any) {
    socket.emit("answerCall", data);
  }

  function sendCall(data: any) {
    socket.emit("call", data);
  }

  const MatchScreen = () => {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: "#050A0E",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Text
          style={{
            fontSize: 36,
            color: "#ffff",
          }}
        >
          Open messaging?
        </Text>
        <View
          style={{
            flexDirection: "row",
            marginTop: 12,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              setType("MATCH");
              setMatch(true);
            }}
            style={{
              height: 50,
              backgroundColor: "#5568FE",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 12,
              marginRight: 8,
            }}
          >
            <Text
              style={{
                fontSize: 16,
                color: "#FFFFFF",
              }}
            >
              Yes
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              setType("MATCH");
              setMatch(false);
            }}
            style={{
              height: 50,
              backgroundColor: "#FF5D5D",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 12,
              marginLeft: 8,
            }}
          >
            <Text
              style={{
                fontSize: 16,
                color: "#FFFFFF",
              }}
            >
              No
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  const JoinScreen = () => {
    return (
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={{
          flex: 1,
          backgroundColor: "#050A0E",
          justifyContent: "center",
          paddingHorizontal: 42,
          width: "100%",
          height: "100%",
        }}
      >
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <>
            <View
              style={{
                padding: 35,
                backgroundColor: "#1A1C22",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 14,
              }}
            >
              <Text
                style={{
                  fontSize: 18,
                  color: "#D0D4DD",
                }}
              >
                Your Caller ID
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 12,
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 32,
                    color: "#ffff",
                    letterSpacing: 6,
                  }}
                >
                  {callerId}
                </Text>
              </View>
            </View>

            <View
              style={{
                backgroundColor: "#1A1C22",
                padding: 40,
                marginTop: 25,
                justifyContent: "center",
                borderRadius: 14,
              }}
            >
              <Text
                style={{
                  fontSize: 18,
                  color: "#D0D4DD",
                }}
              >
                Enter call id of another user
              </Text>
              <TextInputContainer
                placeholder={"Enter Caller ID"}
                value={callerOtherId || ""}
                setValue={(text: any) => {
                  setOtherCallerId(text);
                  console.log("TEST", callerOtherId);
                }}
                keyboardType={"number-pad"}
              />
              <TouchableOpacity
                onPress={() => {
                  setType("OUTGOING_CALL");
                  processCall();
                }}
                style={{
                  height: 50,
                  backgroundColor: "#5568FE",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 12,
                  marginTop: 16,
                }}
              >
                <Text
                  style={{
                    fontSize: 16,
                    color: "#FFFFFF",
                  }}
                >
                  Call Now
                </Text>
              </TouchableOpacity>
            </View>
          </>
        </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
    );
  };

  const OutgoingCallScreen = () => {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "space-around",
          backgroundColor: "#050A0E",
          width: "100%",
          height: "100%",
        }}
      >
        <View
          style={{
            padding: 35,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 14,
          }}
        >
          <Text
            style={{
              fontSize: 16,
              color: "#D0D4DD",
            }}
          >
            Calling to...
          </Text>

          <Text
            style={{
              fontSize: 36,
              marginTop: 12,
              color: "#ffff",
              letterSpacing: 6,
            }}
          >
            {callerOtherId}
          </Text>
        </View>
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TouchableOpacity
            onPress={() => {
              leave();
              setType("MATCH");
            }}
            style={{
              backgroundColor: "#FF5D5D",
              borderRadius: 30,
              height: 60,
              aspectRatio: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CallEnd width={50} height={12} />
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  const IncomingCallScreen = () => {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "space-around",
          backgroundColor: "#050A0E",
          width: "100%",
          height: "100%",
        }}
      >
        <View
          style={{
            padding: 35,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 14,
          }}
        >
          <Text
            style={{
              fontSize: 36,
              marginTop: 12,
              color: "#ffff",
            }}
          >
            {callerOtherId} is calling..
          </Text>
        </View>
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TouchableOpacity
            onPress={() => {
              processAccept();
              setType("WEBRTC_ROOM");
            }}
            style={{
              backgroundColor: "green",
              borderRadius: 30,
              height: 60,
              aspectRatio: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CallAnswer height={28} fill={"#fff"} />
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  function switchCamera() {
    localStream?.getVideoTracks().forEach((track: any) => {
      track._switchCamera();
    });
  }

  function toggleCamera(boolean?: boolean) {
    localWebcamOn
      ? setlocalWebcamOn(boolean || false)
      : setlocalWebcamOn(boolean || true);
    localStream?.getVideoTracks().forEach((track: any) => {
      !localWebcamOn || boolean
        ? (track.enabled = true)
        : (track.enabled = false);
    });
  }

  function toggleMic(boolean?: boolean) {
    localMicOn
      ? setlocalMicOn(boolean || false)
      : setlocalMicOn(boolean || true);
    localStream?.getAudioTracks().forEach((track: any) => {
      !localMicOn || boolean ? (track.enabled = true) : (track.enabled = false);
    });
  }

  function leave() {
    toggleMic(false);
    toggleCamera(false);
    peerConnection.current.close();
    setlocalStream(null);
    setRemoteStream(null);
    setType("MATCH");
  }

  const WebrtcRoomScreen = () => {
    return (
      <Layout
        style={{
          backgroundColor: "#050A0E",
          paddingHorizontal: 12,
          paddingVertical: 12,
          width: "100%",
          height: "100%",
        }}
      >
        <View
          style={{
            maxHeight: Dimensions.get("window").height / 2,
          }}
        >
          <View
            style={{
              flexDirection: "row",
            }}
          ></View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 12,
            }}
          >
            <ConnectFour date={virtual} socket={socket} route={route} />
            {localStream ? (
              <RTCView
                style={{
                  backgroundColor: "#050A0E",
                  height: Dimensions.get("window").height / 2,
                  maxHeight: Dimensions.get("window").height / 2,
                }}
                stream={localStream}
              />
            ) : null}
            {remoteStream ? (
              <RTCView
                style={{
                  backgroundColor: "#050A0E",
                  maxHeight: Dimensions.get("window").height / 2,
                  height: Dimensions.get("window").height / 2,
                }}
                stream={remoteStream}
              />
            ) : null}
          </View>
        </View>
        <View
          style={{
            maxHeight: 200,
            marginVertical: 12,
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <IconContainer
            backgroundColor={"red"}
            onPress={() => {
              leave();
              setType("MATCH");
            }}
            Icon={() => {
              return <CallEnd height={26} width={26} fill="#FFF" />;
            }}
          />
          <IconContainer
            style={{
              borderWidth: 1.5,
              borderColor: "#2B3034",
            }}
            backgroundColor={!localMicOn ? "#fff" : "transparent"}
            onPress={() => {
              toggleMic();
            }}
            Icon={() => {
              return localMicOn ? (
                <MicOn height={24} width={24} fill="#FFF" />
              ) : (
                <MicOff height={28} width={28} fill="#1D2939" />
              );
            }}
          />
          <IconContainer
            style={{
              borderWidth: 1.5,
              borderColor: "#2B3034",
            }}
            backgroundColor={!localWebcamOn ? "#fff" : "transparent"}
            onPress={() => {
              toggleCamera();
            }}
            Icon={() => {
              return localWebcamOn ? (
                <VideoOn height={24} width={24} fill="#FFF" />
              ) : (
                <VideoOff height={36} width={36} fill="#1D2939" />
              );
            }}
          />
          <IconContainer
            style={{
              borderWidth: 1.5,
              borderColor: "#2B3034",
            }}
            backgroundColor={"transparent"}
            onPress={() => {
              switchCamera();
            }}
            Icon={() => {
              return <CameraSwitch height={24} width={24} fill="#FFF" />;
            }}
          />
        </View>
      </Layout>
    );
  };

  switch (type) {
    case "JOIN":
      return JoinScreen();
    case "INCOMING_CALL":
      return IncomingCallScreen();
    case "OUTGOING_CALL":
      return OutgoingCallScreen();
    case "WEBRTC_ROOM":
      return WebrtcRoomScreen();
    case "MATCH":
      return MatchScreen();
    default:
      return null;
  }
};

export default AudioCall;
