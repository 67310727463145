import * as theme from "@eva-design/eva";

export const myTheme = {
  ...theme,
  "color-primary-100": "#E2DFFF",
  "color-primary-200": "#C4C0FF",
  "color-primary-300": "#A7A1FF",
  "color-primary-400": "#908AFF",
  "color-primary-500": "#6B63FF",
  "color-primary-600": "#4F48DB",
  "color-primary-700": "#3731B7",
  "color-primary-800": "#231F93",
  "color-primary-900": "#16137A",
  "color-primary-default": "$color-primary-500",
  "color-primary-hover": "$color-primary-400",
  "color-primary-focus": "$color-primary-600",
  "color-primary-active": "$color-primary-600",
  "color-primary-disabled": "$color-basic-transparent-300",
  "color-primary-default-border": "$color-primary-default",
  "color-primary-hover-border": "$color-primary-hover",
  "color-primary-focus-border": "$color-primary-700",
  "color-primary-active-border": "$color-primary-active",
  "color-primary-disabled-border": "$color-primary-disabled",
  "color-success-100": "#DDFBD7",
  "color-success-200": "#B5F8B0",
  "color-success-300": "#85EA87",
  "color-success-400": "#62D570",
  "color-success-500": "#34BA51",
  "color-success-600": "#269F4C",
  "color-success-700": "#1A8546",
  "color-success-800": "#106B3E",
  "color-success-900": "#095939",
  "color-success-default": "$color-success-500",
  "color-success-hover": "$color-success-400",
  "color-success-focus": "$color-success-600",
  "color-success-active": "$color-success-600",
  "color-success-disabled": "$color-basic-transparent-300",
  "color-success-default-border": "$color-success-default",
  "color-success-hover-border": "$color-success-hover",
  "color-success-focus-border": "$color-success-700",
  "color-success-active-border": "$color-success-active",
  "color-success-disabled-border": "$color-success-disabled",
  "color-info-100": "#DBF1FE",
  "color-info-200": "#B8E1FD",
  "color-info-300": "#94CCFB",
  "color-info-400": "#79B8F8",
  "color-info-500": "#4E99F4",
  "color-info-600": "#3977D1",
  "color-info-700": "#2758AF",
  "color-info-800": "#183D8D",
  "color-info-900": "#0E2A75",
  "color-info-default": "$color-info-500",
  "color-info-hover": "$color-info-400",
  "color-info-focus": "$color-info-600",
  "color-info-active": "$color-info-600",
  "color-info-disabled": "$color-basic-transparent-300",
  "color-info-default-border": "$color-info-default",
  "color-info-hover-border": "$color-info-hover",
  "color-info-focus-border": "$color-info-700",
  "color-info-active-border": "$color-info-active",
  "color-info-disabled-border": "$color-info-disabled",
  "color-warning-100": "#FEFDD4",
  "color-warning-200": "#FEFCA9",
  "color-warning-300": "#FDF97E",
  "color-warning-400": "#FBF65E",
  "color-warning-500": "#F9F22A",
  "color-warning-600": "#D6CF1E",
  "color-warning-700": "#B3AC15",
  "color-warning-800": "#908A0D",
  "color-warning-900": "#777208",
  "color-warning-default": "$color-warning-500",
  "color-warning-hover": "$color-warning-400",
  "color-warning-focus": "$color-warning-600",
  "color-warning-active": "$color-warning-600",
  "color-warning-disabled": "$color-basic-transparent-300",
  "color-warning-default-border": "$color-warning-default",
  "color-warning-hover-border": "$color-warning-hover",
  "color-warning-focus-border": "$color-warning-700",
  "color-warning-active-border": "$color-warning-active",
  "color-warning-disabled-border": "$color-warning-disabled",
  "color-danger-100": "#FFE7D4",
  "color-danger-200": "#FFC8AA",
  "color-danger-300": "#FFA37F",
  "color-danger-400": "#FF7F60",
  "color-danger-500": "#FF442B",
  "color-danger-600": "#DB261F",
  "color-danger-700": "#B7151C",
  "color-danger-800": "#930D1E",
  "color-danger-900": "#7A081F",
  "color-danger-default": "$color-danger-500",
  "color-danger-hover": "$color-danger-400",
  "color-danger-focus": "$color-danger-600",
  "color-danger-active": "$color-danger-600",
  "color-danger-disabled": "$color-basic-transparent-300",
  "color-danger-default-border": "$color-danger-default",
  "color-danger-hover-border": "$color-danger-hover",
  "color-danger-focus-border": "$color-danger-700",
  "color-danger-active-border": "$color-danger-active",
  "color-danger-disabled-border": "$color-danger-disabled",
  "color-basic-100": "#FFFFFF",
  "color-basic-200": "#F5F5F5",
  "color-basic-300": "#F5F5F5",
  "color-basic-400": "#D4D4D4",
  "color-basic-500": "#B3B3B3",
  "color-basic-600": "#808080",
  "color-basic-700": "#4A4A4A",
  "color-basic-800": "#383838",
  "color-basic-900": "#292929",
  "color-basic-1000": "#1F1F1F",
  "color-basic-1100": "#141414",
  "color-basic-default": "$color-basic-500",
  "color-basic-hover": "$color-basic-400",
  "color-basic-focus": "$color-basic-600",
  "color-basic-active": "$color-basic-600",
  "color-basic-disabled": "$color-basic-transparent-300",
  "color-basic-default-border": "$color-basic-default",
  "color-basic-hover-border": "$color-basic-hover",
  "color-basic-focus-border": "$color-basic-700",
  "color-basic-active-border": "$color-basic-active",
  "color-basic-disabled-border": "$color-basic-disabled",
  "color-basic-transparent-100": "rgba(128, 128, 128, 0.08)",
  "color-basic-transparent-200": "rgba(128, 128, 128, 0.16)",
  "color-basic-transparent-300": "rgba(128, 128, 128, 0.24)",
  "color-basic-transparent-400": "rgba(128, 128, 128, 0.32)",
  "color-basic-transparent-500": "rgba(128, 128, 128, 0.4)",
  "color-basic-transparent-600": "rgba(128, 128, 128, 0.48)",
  // Configurations for the custom theme
  "text-basic-color": "$color-basic-400",
};
