import * as React from "react";
import { Alert, View } from "react-native";
import { Layout, Text, Card, Button } from "@ui-kitten/components";
import { TouchableOpacity } from "react-native-gesture-handler";
import AsyncStorage from "@react-native-async-storage/async-storage";

const ConnectFour = ({ route, socket, date }: any) => {
  const [callerId, setCallerId] = React.useState("");
  const [userId, setUserId] = React.useState(0);
  const [player, setPlayer] = React.useState("red");
  const [board, setBoard] = React.useState(
    new Array(7).fill(Array(6).fill(null))
  );

  const resetGame = () => {
    setPlayer("red");
    setBoard(new Array(7).fill(Array(6).fill(null)));
    socket.emit("newGame", {
      board: new Array(7).fill(Array(6).fill(null)),
      player: "red",
      callerId: route?.params?.userCallerId,
      otherCallerId: route?.params?.matchCallerId,
    });
  };

  const dropPiece = (column: number) => {
    for (let row = 5; row >= 0; row--) {
      if (!board[column][row]) {
        const newBoard = board.map((row: any) => row.slice());
        newBoard[column][row] = player;

        setBoard(newBoard);
        setPlayer(player === "red" ? "yellow" : "red");

        socket.emit("move", {
          board: newBoard,
          player: player === "red" ? "yellow" : "red",
          callerId: route?.params?.userCallerId,
          otherCallerId: route?.params?.matchCallerId,
        });

        break;
      }
    }
  };

  const checkWinner = () => {
    for (let row = 0; row < 7; row++) {
      for (let col = 0; col < 6; col++) {
        if (
          col + 3 < 6 &&
          board[row][col] !== null &&
          board[row][col] === board[row][col + 1] &&
          board[row][col] === board[row][col + 2] &&
          board[row][col] === board[row][col + 3]
        ) {
          return board[row][col];
        }
        if (
          row + 3 < 7 &&
          board[row][col] !== null &&
          board[row][col] === board[row + 1][col] &&
          board[row][col] === board[row + 2][col] &&
          board[row][col] === board[row + 3][col]
        ) {
          return board[row][col];
        }
        if (
          row + 3 < 7 &&
          col + 3 < 6 &&
          board[row][col] !== null &&
          board[row][col] === board[row + 1][col + 1] &&
          board[row][col] === board[row + 2][col + 2] &&
          board[row][col] === board[row + 3][col + 3]
        ) {
          return board[row][col];
        }
        if (
          row + 3 < 7 &&
          col - 3 >= 0 &&
          board[row][col] !== null &&
          board[row][col] === board[row + 1][col - 1] &&
          board[row][col] === board[row + 2][col - 2] &&
          board[row][col] === board[row + 3][col - 3]
        ) {
          return board[row][col];
        }
      }
    }
    return null;
  };

  const renderBoard = () => {
    return board.map((row, rowIndex) => {
      return (
        <View
          key={rowIndex}
          style={{ flexDirection: "column", backgroundColor: "transparent" }}
        >
          {row?.map((cell: any, cellIndex: any) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  if (date?.user_id === userId && player === "red")
                    dropPiece(rowIndex);
                  else if (date?.user_id !== userId && player === "yellow")
                    dropPiece(rowIndex);
                }}
                key={cellIndex}
                style={{
                  width: 50,
                  height: 50,
                  backgroundColor: "white",
                  borderWidth: 1,
                  borderColor: "black",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    width: 40,
                    height: 40,
                    backgroundColor: cell,
                    borderRadius: 50,
                  }}
                />
              </TouchableOpacity>
            );
          })}
        </View>
      );
    });
  };

  React.useEffect(() => {
    const winner = checkWinner();
    if (winner !== null) {
      Alert.alert(`${winner} wins!`);
      resetGame();
    }
  }, [board, player]);

  React.useEffect(() => {
    socket.on("move", (data: any) => {
      const { board, player } = data;
      setBoard(board.map((row: any) => row.slice()));
      setPlayer(player);
    });
    socket.on("newGame", (data: any) => {
      const { board, player } = data;
      setBoard(board.map((row: any) => row.slice()));
      setPlayer(player);
    });
    return () => {
      socket.disconnect();
    };
  }, [socket]);

  React.useEffect(() => {
    async function getCallerId() {
      const callerId = (await AsyncStorage.getItem("callerId")) ?? "";
      setCallerId(callerId);

      const userId = (await AsyncStorage.getItem("userId")) ?? "";
      setUserId(Number(userId));
    }
    getCallerId();
  }, []);

  return (
    <Layout style={{ backgroundColor: "transparent" }}>
      <View style={{ backgroundColor: "transparent" }}>
        <Card style={{ backgroundColor: "transparent" }}>
          <Text category="h1">Connect Four</Text>
          <Text>
            Connect Four is a two-player connection game in which the players
            first choose a color and then take turns dropping colored discs from
            the top into a seven-column, six-row vertically suspended grid. The
            pieces fall straight down, occupying the lowest available space
            within the column. The objective of the game is to be the first to
            form a horizontal, vertical, or diagonal line of four of one's own
            discs. Connect Four is a solved game. The first player can always
            win by playing the right moves.
          </Text>
          <Text>{player?.toUpperCase()} players turn</Text>
          <View
            style={{ flexDirection: "row", backgroundColor: "rbga(0,0,0,0.8)" }}
          >
            {renderBoard()}
          </View>
          <Button onPress={resetGame}>Reset Game</Button>
        </Card>
      </View>
    </Layout>
  );
};

export default ConnectFour;
