import * as React from "react";

import { Layout } from "@ui-kitten/components";

import Age from "../components/pages/Age";
import {
  AuthContext,
  ThemeContext,
  type ThemeContextProps,
  type AuthContextProps,
} from "app/utils/context";
import { darkGrey, white } from "app/styles/theme";
import { StyleSheet } from "react-native";

const AgeSetup = (props: any) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;

  React.useLayoutEffect(() => {
    setRoute(props?.route?.name);
  }, [setRoute, props?.route?.name]);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: themes === "light" ? white : darkGrey,
      })}
    >
      <Age {...props} edit />
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",

    flex: 1,
    justifyContent: "center",
  },
});

export default AgeSetup;
