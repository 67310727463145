import * as React from "react";

import { Layout } from "@ui-kitten/components";
import { StyleSheet } from "react-native";

import VideoBackground from "app/components/video/VideoBackground";
import { darkGrey, white } from "app/styles/theme";
import {
  AuthContext,
  ThemeContext,
  type ThemeContextProps,
  type AuthContextProps,
} from "app/utils/context";

const PrivatePolicy: React.FC<any> = ({ navigation, route }) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;

  React.useLayoutEffect(() => {
    setRoute(route?.name);
  }, [setRoute, route?.name]);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: themes === "light" ? white : darkGrey,
      })}
    >
      <VideoBackground style={styles.videoBackground}>
        <iframe
          method="GET"
          src="/private-policies"
          title="Private Policy"
          width="100%"
          height="100%"
          style={styles.iFrame}
        />
      </VideoBackground>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  iFrame: {
    position: "fixed",
    top: 50,
    left: 0,
    backgroundColor: "rgba(255, 255, 255, 0.4)",
  },
  videoBackground: {
    minHeight: "100%",
    minWidth: "100%",
    position: "absolute",
  },
});

export default PrivatePolicy;
