import * as React from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  TextInput,
} from "react-native";
import axios from "axios";
import { ThemeContext } from "app/utils/context";
import { darkGrey, white } from "app/styles/theme";
import MenuOptions from "app/components/icons/MenuOptions";
import { HOSTNAME } from "app/utils/ajax";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Button, Modal, Card } from "@ui-kitten/components";

type Props = {
  navigation: any;
  route: any;
  matchId: number | string;
};

const Options: React.FC<Props> = ({ navigation, route, matchId }) => {
  const { themes } = React.useContext(ThemeContext);
  const [visible, setVisible] = React.useState(false);
  const [reason, setReason] = React.useState("");
  const [visibleReport, setVisibleReport] = React.useState(false);
  const [title, setTitle] = React.useState("");

  const userReport = async () => {
    try {
      const token = await AsyncStorage.getItem("token");
      await axios.post(
        HOSTNAME + "/api/v1/user_reports",
        {
          user_id: matchId,
          reason,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const userBlock = async () => {
    try {
      const token = await AsyncStorage.getItem("token");
      await axios.post(
        HOSTNAME + "/api/v1/user_blocks",
        {
          user_id: matchId,
          reason,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <View style={styles.container}>
      {!visible && (
        <TouchableOpacity
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 20,
          }}
          onPress={() => setVisible(!visible)}
        >
          <MenuOptions />
        </TouchableOpacity>
      )}
      {visible && (
        <View
          style={Object.assign({}, styles.popup, {
            backgroundColor: themes === "light" ? white : darkGrey,
          })}
        >
          <TouchableOpacity
            onPress={() => {
              setVisible(false);
              setVisibleReport(true);
              setTitle("Block");
            }}
          >
            <Text style={{ color: themes === "light" ? darkGrey : white }}>
              Block
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              setVisible(false);
              setVisibleReport(true);
              setTitle("Report");
            }}
          >
            <Text style={{ color: themes === "light" ? darkGrey : white }}>
              Report
            </Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => setVisible(false)}>
            <Text style={{ color: themes === "light" ? darkGrey : white }}>
              Cancel
            </Text>
          </TouchableOpacity>
        </View>
      )}
      <Modal
        visible={visibleReport}
        backdropStyle={styles.backdrop}
        onBackdropPress={() => setVisibleReport(false)}
      >
        <Card
          style={Object.assign({}, styles.popup, {
            backgroundColor: themes === "light" ? white : darkGrey,
          })}
        >
          <TouchableOpacity onPress={() => setVisibleReport(false)}>
            <Text>Close</Text>
          </TouchableOpacity>
          <Text>{title} User</Text>
          <Text>Reason</Text>
          <TextInput
            style={{
              backgroundColor: themes === "light" ? white : darkGrey,
              color: themes === "light" ? darkGrey : white,
              padding: 10,
              borderRadius: 10,
            }}
            onChangeText={(text) => setReason(text)}
          />
          <Button
            disabled={!reason}
            onPress={() => {
              if (title === "Block") {
                userBlock();
              } else if (title === "Report") {
                userReport();
              }
              setVisibleReport(false);
              setVisible(false);
            }}
          >
            <Text>Submit</Text>
          </Button>
        </Card>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  container: {
    position: "relative",
  },
  popup: {},
});

export default Options;
