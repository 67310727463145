import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Link } from "@react-navigation/native";
import { Card, Layout, List, Text } from "@ui-kitten/components";
import axios from "axios";
import {
  Image,
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from "react-native";

import Loader from "app/components/loaders/Spinner";
import Scroll from "app/components/scroll/Scroll";
import { black, darkGrey, white } from "app/styles/theme";
import { fetchSubscription, HOSTNAME } from "app/utils/ajax";
import {
  AuthContext,
  ThemeContext,
  type ThemeContextProps,
  type AuthContextProps,
} from "app/utils/context";
import Options from "app/components/popups/Options";

const Messaging: React.FC<any> = ({ navigation, route }) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const layout = useWindowDimensions();
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;

  const [loading, setLoading] = React.useState(true);
  const [threads, setThreads] = React.useState([]);
  const [userId, setUserId] = React.useState(0);

  const fetchMessages = async () => {
    setLoading(true);
    try {
      const userId = await AsyncStorage.getItem("userId");
      setUserId(Number(userId));
      const token = await AsyncStorage.getItem("token");
      const { data } = await axios.get(`${HOSTNAME}/api/v1/message_threads`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setThreads(data?.data || []);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  React.useLayoutEffect(() => {
    // fetchSubscription().then((sub) => {
    //   if (!sub) {
    //     navigation.navigate("Membership Setup");
    //   }
    // });
    setRoute(route?.name);
  }, [setRoute, route?.name, navigation]);

  React.useEffect(() => {
    fetchMessages();
  }, []);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: themes === "light" ? white : darkGrey,
      })}
    >
      <Scroll>
        <View style={styles.row}>
          <View></View>
          <Text category="h1">Messages</Text>
          <TouchableOpacity onPress={fetchMessages}>
            <Text style={styles.icon}>⟳</Text>
          </TouchableOpacity>
        </View>
        {loading ? (
          <Loader />
        ) : threads.length > 0 ? (
          <List
            refreshing={loading}
            onRefresh={fetchMessages}
            data={threads}
            style={Object.assign({}, styles.list, { height: layout.height })}
            contentContainerStyle={Object.assign({}, styles.list, {
              height: layout.height,
            })}
            renderItem={({ item, index }: any) => (
              <View
                key={index}
                style={Object.assign({}, styles.card, {
                  backgroundColor: themes === "light" ? white : darkGrey,
                  borderColor: themes === "light" ? black : white,
                })}
              >
                <View style={styles.row}>
                  <Link
                    to={{
                      screen: "Chat",
                      params: {
                        id: item?.id,
                        matchId:
                          item?.user_id === Number(userId)
                            ? item?.match?.user_id
                            : item?.user_id,
                      },
                    }}
                    key={index}
                    style={styles.row}
                  >
                    <View style={styles.cardHeader}>
                      <Image
                        style={styles.cardImage}
                        source={{
                          uri:
                            item?.user_profile?.user_id === Number(userId)
                              ? item?.match_photos[0]?.photo_url
                              : item?.user_photos[0]?.photo_url,
                        }}
                      />
                    </View>
                    <View style={styles.cardSection}>
                      <Text category="h6" style={styles.cardName}>
                        {item?.user_profile?.user_id === Number(userId)
                          ? item?.match_profile?.first_name
                          : item?.user_profile?.first_name}{" "}
                        {item?.user_profile?.user_id === Number(userId)
                          ? item?.match_profile?.last_name
                          : item?.user_profile?.last_name}
                      </Text>
                      <Text style={styles.cardMessage}>
                        {item?.messages?.length > 0
                          ? item?.messages[item?.messages?.length - 1]?.message
                          : "No messages yet"}
                      </Text>
                    </View>
                  </Link>
                  <Options
                    navigation={navigation}
                    route={route}
                    matchId={item?.user_profile?.user_id}
                  />
                </View>
              </View>
            )}
          />
        ) : (
          <Text>No messages yet</Text>
        )}
      </Scroll>
    </Layout>
  );
};

const styles = StyleSheet.create({
  card: {
    alignItems: "center",
    borderRadius: 4,
    borderWidth: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  cardHeader: {
    alignItems: "center",
    flexDirection: "row",
    height: "100%",
    justifyContent: "center",
    width: "25%",
  },
  cardImage: {
    borderRadius: 50,
    height: 75,
    textAlign: "center",
    width: 75,
  },
  cardMessage: {
    flex: 1,
    marginLeft: 10,
    textAlign: "center",
  },
  cardName: {
    marginBottom: 5,
    marginLeft: 10,
    textAlign: "center",
  },
  cardSection: {
    alignItems: "flex-start",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    width: "75%",
  },
  container: {
    alignItems: "center",
    flex: 1,
    height: "100%",
    justifyContent: "center",
    padding: 10,
    width: "100%",
  },
  icon: {
    fontSize: 32,
  },
  list: {
    width: "100%",
    flexDirection: "column",
    padding: 10,
  },
  row: {
    alignContent: "center",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 40,
    width: "100%",
  },
});

export default Messaging;
