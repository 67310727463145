import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Layout, Card, CheckBox, Text } from "@ui-kitten/components";
import axios from "axios";
import { StyleSheet } from "react-native";

import { HOSTNAME } from "app/utils/ajax";
import {
  AuthContext,
  ThemeContext,
  type ThemeContextProps,
  type AuthContextProps,
} from "app/utils/context";

const LIST = [
  "White",
  "Black",
  "Asian",
  "Indian",
  "Hispanic",
  "Middle Eastern",
  "Native American",
  "Pacific Islander",
  "Other",
];

const RacialBackground: React.FC<any> = ({
  navigation,
  route,
  edit = false,
}) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;

  const [racial_background, setRacialBackground]: any = React.useState([]);

  const setChecked = (nextChange: boolean, item: any) => {
    const array = (racial_background || []).slice(0);
    if (nextChange) {
      array.push(item);
    } else {
      const index = array.findIndex((e: any) => e === item);
      array.splice(index, 1);
    }
    setRacialBackground(array);
    setRacialBackgrounds(array);
  };

  const fetchProfile = async () => {
    try {
      const token = await AsyncStorage.getItem("token");
      const res = await axios.get(`${HOSTNAME}/api/v1/user_profile`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setRacialBackground(res?.data?.data[0]?.racial_background?.split(","));
    } catch (error) {
      console.error(error);
    }
  };

  const setRacialBackgrounds = async (array: any) => {
    try {
      const token = await AsyncStorage.getItem("token");
      const res = await axios.put(
        `${HOSTNAME}/api/v1/user_profile`,
        {
          racial_background: array?.join(","),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setRacialBackground(res?.data?.data[0]?.racial_background?.split(","));
    } catch (error) {
      console.error(error);
    }
  };

  React.useLayoutEffect(() => {
    setRoute(route?.name);
  }, [setRoute, route?.name]);

  React.useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <Layout>
      <Card>
        <Text>Racial Background</Text>
        {LIST.map((item, index) => (
          <CheckBox
            checked={!!racial_background?.find((e: any) => e === item)}
            onChange={(nextChecked) => {
              setChecked(nextChecked, item);
            }}
            key={index}
          >
            {item}
          </CheckBox>
        ))}
      </Card>
    </Layout>
  );
};

const styles = StyleSheet.create({
  input: {
    width: "100%",
  },
});

export default RacialBackground;
