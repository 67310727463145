import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";

export const HOSTNAME =
  process.env.NODE_ENV === "development"
    ? "https://localhost:8080"
    : "https://api.loveindahouse.com";

export const WEBSOCKETS_HOSTNAME =
  process.env.NODE_ENV === "development"
    ? "https://localhost:3500"
    : "https://ws.loveindahouse.com";

export const redirectToHomepageWhenExpired = async (
  error: any,
  navigation: any,
  setRoute: any
): Promise<void> => {
  if (Number(error?.status) === 401 || Number(error?.status) === 403) {
    await AsyncStorage.multiRemove([
      "token",
      "phoneNumber",
      "isActive",
      "deletedAt",
      "userId",
      "subscription",
      "callerId",
    ]);
    setRoute("Login");
    navigation.navigate("Login", {
      message: "Token expired! Please login back in.",
    });
  }
};

export const fetchSubscription = async () => {
  try {
    const subscription = await AsyncStorage.getItem("subscription");
    if (subscription) return subscription;

    const token = await AsyncStorage.getItem("token");
    const response = await axios.get(`${HOSTNAME}/api/v1/user_subscription`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = response?.data;
    return data?.data || {};
  } catch (error) {
    console.error(error);
  }
};
