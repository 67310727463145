import * as React from "react";

import { Layout, Text } from "@ui-kitten/components";
import { StyleSheet, View } from "react-native";

import UserDatingPreferences from "app/components/pages/DatingPreferences";
import Scroll from "app/components/scroll/Scroll";
import { darkGrey, white } from "app/styles/theme";
import { fetchSubscription } from "app/utils/ajax";
import {
  AuthContext,
  ThemeContext,
  type ThemeContextProps,
  type AuthContextProps,
} from "app/utils/context";

const DatingPreferences: React.FC<any> = (props) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;

  React.useLayoutEffect(() => {
    // fetchSubscription().then((sub) => {
    //   if (!sub) {
    //     navigation.navigate("Membership Setup");
    //   }
    // });
    setRoute(props.route?.name);
  }, [setRoute, props.route?.name, props.navigation]);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: themes === "light" ? white : darkGrey,
      })}
    >
      <Scroll>
        <View style={styles.row}>
          <View></View>
          <Text style={styles.title} category="h1">
            Dating Preferences
          </Text>
          <View></View>
        </View>
        <UserDatingPreferences {...props} />
      </Scroll>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",

    flex: 1,
    justifyContent: "center",
    width: "100%",
  },
  row: {
    alignContent: "center",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  title: {
    fontSize: 32,
    textAlign: "center",
  },
});

export default DatingPreferences;
