import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Button, Card, Layout, Text } from "@ui-kitten/components";
import { StyleSheet, View } from "react-native";
import { darkGrey, white, primary } from "app/styles/theme";
import {
  AuthContext,
  ThemeContext,
  type ThemeContextProps,
  type AuthContextProps,
} from "app/utils/context";

const membershipDetails = [
  {
    title: "Monthly",
    price: "$9.99",
    description: "Billed monthly",
    prodId: "prod_QJOVB0mVOpISKL",
    priceId: "price_1PSliXGYzp644caV9zF1VYTK",
  },
  {
    title: "Quarterly",
    price: "$24.99",
    description: "Billed quarterly",
    prodId: "prod_QJOWysRpRqSb5Y",
    priceId: "price_1PSljEGYzp644caVbmHTjpje",
  },
  {
    title: "Bi-Yearly",
    price: "$49.99",
    description: "Billed bi-yearly",
    prodId: "prod_QJOWSIdwElaw4w",
    priceId: "price_1PSljlGYzp644caVuT5bSdYg",
  },
];

const MembershipSetup: React.FC<any> = ({ navigation, route }) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;

  const [subscriptions] = React.useState(membershipDetails);
  const [selected, setSelected] = React.useState(0);

  const onSubmit = async () => {
    try {
      await AsyncStorage.setItem(
        "subscription",
        JSON.stringify(subscriptions[selected])
      );
      navigation.navigate("Checkout");
    } catch (error) {
      console.error(error);
    }
  };

  React.useLayoutEffect(() => {
    setRoute(route?.name);
  }, [setRoute, route?.name]);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: themes === "light" ? white : darkGrey,
      })}
    >
      <Text>Membership</Text>
      <View style={styles.row}>
        {subscriptions.map((item, index) => (
          <Card
            key={index}
            style={selected === index ? styles.outline : {}}
            onPress={() => {
              setSelected(index);
            }}
            status={selected === index ? "primary" : "basic"}
          >
            <Text>{item.title}</Text>
            <Text>{item.price}</Text>
            <Text>{item.description}</Text>
          </Card>
        ))}
      </View>
      <Button
        onPress={() => {
          onSubmit().catch(console.error);
        }}
      >
        Submit
      </Button>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",

    flex: 1,
    justifyContent: "center",
  },
  outline: {
    borderColor: primary,
    borderWidth: 5,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
});

export default MembershipSetup;
