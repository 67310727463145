import AgeSetupScreen from "../auth/AgeSetup";
// import CheckoutScreen from "../auth/Checkout";
import DatingPreferencesSetupScreen from "../auth/DatingPreferencesSetup";
import ForgottenPasswordScreen from "../auth/ForgottenPassword";
import ForgottenTwoWayAuthScreen from "../auth/ForgottenTwoWayAuth";
import LocationSetupScreen from "../auth/LocationSetup";
import LoginScreen from "../auth/Login";
import MembershipSetupScreen from "../auth/MembershipSetup";
import PersonalDetailsSetupScreen from "../auth/PersonalDetailsSetup";
import ProfileSetupScreen from "../auth/ProfileSetup";
import RedirectHomeScreen from "../auth/RedirectHome";
import ResetPasswordScreen from "../auth/ResetPassword";
import SignupScreen from "../auth/Signup";
import TwoWayAuthScreen from "../auth/TwoWayAuth";
import {
  authOptions,
  homeOptions,
  twoWayAuthOptions,
} from "../components/headers/Options";
import AccountScreen from "../features/Account";
import AgeScreen from "../features/Age";
import CalendarScreen from "../features/Calendar";
import ChatScreen from "../features/Chat";
import DatingPreferencesScreen from "../features/DatingPreferences";
import HomeScreen from "../features/Home";
import LocationScreen from "../features/Location";
import Logout from "../features/Logout";
import MessagingScreen from "../features/Messaging";
import PersonalDetailsScreen from "../features/PersonalDetails";
import ProfileScreen from "../features/Profile";
import ProfileDetailScreen from "../features/ProfileDetail";
import VirtualDateScreen from "../features/VirtualDate";
import MenuScreen from "../menu/Menu";
import AboutScreen from "../public/About";
import ContactScreen from "../public/Contact";
import IndexScreen from "../public/Index";
import PricingScreen from "../public/Pricing";
import PrivatePolicyScreen from "../public/PrivatePolicy";
import TermsAndConditionsScreen from "../public/TermsAndConditions";
// import { HOSTNAME } from "../utils/ajax";

export const config = [
  {
    name: "Index",
    component: IndexScreen,
    options: homeOptions,
    token: false,
    link: "/",
  },
  {
    name: "About",
    component: AboutScreen,
    options: homeOptions,
    token: false,
    link: "/about",
  },
  {
    name: "Contact",
    component: ContactScreen,
    options: homeOptions,
    token: false,
    link: "/contact",
  },
  {
    name: "Pricing",
    component: PricingScreen,
    options: homeOptions,
    token: false,
    link: "/pricing",
  },
  {
    name: "Private Policy",
    component: PrivatePolicyScreen,
    options: homeOptions,
    token: false,
    link: "/private-policy",
  },
  {
    name: "Terms & Conditions",
    component: TermsAndConditionsScreen,
    options: homeOptions,
    token: false,
    link: "/terms-and-conditions",
  },
  {
    name: "Login",
    component: LoginScreen,
    options: homeOptions,
    link: "/login",
    token: false,
  },
  {
    name: "Sign Up",
    component: SignupScreen,
    options: homeOptions,
    token: false,
    link: "/signup",
  },
  {
    name: "Forgotten Password",
    component: ForgottenPasswordScreen,
    options: homeOptions,
    token: false,
    link: "/forgotten-password",
  },
  {
    name: "Two Way Auth",
    component: TwoWayAuthScreen,
    options: twoWayAuthOptions,
    token: false,
    link: "/two-way-auth-page",
  },
  {
    name: "Forgotten Two Way Auth",
    component: ForgottenTwoWayAuthScreen,
    options: twoWayAuthOptions,
    token: false,
    link: "/forgotten-two-way-auth-page",
  },
  {
    name: "Dating Preferences Setup",
    component: DatingPreferencesSetupScreen,
    options: twoWayAuthOptions,
    token: false,
    link: "/dating-preferences-setup",
  },
  {
    name: "Personal Details Setup",
    component: PersonalDetailsSetupScreen,
    options: twoWayAuthOptions,
    token: false,
    link: "/personal-details-setup",
  },
  {
    name: "Profile Setup",
    component: ProfileSetupScreen,
    options: twoWayAuthOptions,
    token: false,
    link: "/profile-setup",
  },
  {
    name: "Age Setup",
    component: AgeSetupScreen,
    options: twoWayAuthOptions,
    token: false,
    link: "/age-setup",
  },
  {
    name: "Location Setup",
    component: LocationSetupScreen,
    options: twoWayAuthOptions,
    token: false,
    link: "/location-setup",
  },
  {
    name: "Membership Setup",
    component: MembershipSetupScreen,
    options: twoWayAuthOptions,
    token: false,
    link: "/membership-setup",
  },
  // {
  //   name: "Checkout",
  //   component: CheckoutScreen,
  //   options: twoWayAuthOptions,
  //   token: false,
  //   link: "/checkout",
  // },
  {
    name: "Redirect Home",
    component: RedirectHomeScreen,
    options: twoWayAuthOptions,
    token: false,
    link: "/redirect",
  },
  {
    name: "Reset Password",
    component: ResetPasswordScreen,
    options: twoWayAuthOptions,
    token: true,
    link: "/reset-password",
  },
  {
    name: "Home",
    component: HomeScreen,
    options: authOptions,
    menu: true,
    token: true,
    link: "/home",
  },
  {
    name: "Virtual Dates",
    component: VirtualDateScreen,
    options: authOptions,
    link: "/virtual-dates/:userCallerId/matches/:matchCallerId",
  },
  {
    name: "Messaging",
    component: MessagingScreen,
    options: authOptions,
    menu: true,
    token: true,
    link: "/messaging",
  },
  {
    name: "Chat",
    component: ChatScreen,
    options: authOptions,
    // token: true,
    parse: {
      id: (id: any) => id,
    },
    link: "/messaging/:id",
  },
  {
    name: "Profile",
    component: ProfileScreen,
    options: authOptions,
    menu: true,
    token: true,
    link: "/profile",
  },
  {
    name: "Age",
    component: AgeScreen,
    options: authOptions,
    token: true,
    menu: true,
    link: "/age",
  },
  {
    name: "Profile Detail",
    component: ProfileDetailScreen,
    options: authOptions,
    token: true,
    link: "/profile/:id",
  },
  {
    name: "Location",
    component: LocationScreen,
    options: authOptions,
    token: true,
    link: "/location",
    menu: true,
  },
  {
    name: "Dating Preferences",
    component: DatingPreferencesScreen,
    options: authOptions,
    menu: true,
    token: true,
    link: "/dating-preferences",
  },
  {
    name: "Personal Details",
    component: PersonalDetailsScreen,
    options: authOptions,
    menu: true,
    token: true,
    link: "/personal-details",
  },
  {
    name: "Account",
    component: AccountScreen,
    options: authOptions,
    menu: true,
    token: true,
    link: "/account",
  },
  {
    name: "Calendar",
    component: CalendarScreen,
    options: authOptions,
    token: true,
    link: "/calendar",
  },
  {
    name: "Menu",
    component: MenuScreen,
    options: authOptions,
    token: true,
    link: "/menu",
  },
  {
    name: "Logout",
    component: Logout,
    options: authOptions,
    token: true,
    link: "/logout",
  },
];

export const screenConfig = config.map((config) => {
  return {
    name: config.name,
    component: config.component,
    options: config.options,
    token: config.token,
  };
});

export const menuConfig = config
  .filter((config) => config.menu)
  .map((config: any) => {
    return {
      name: config.name,
      pageName: config.name,
      locked: config?.locked || false,
    };
  });

export const linkConfig = {
  prefixes: [
    "https://www.loveindahouse.com",
    "https://beta.loveindahouse.com",
    "https://loveindahouse.com",
    "https://modernmatchmaking.io",
    "https://www.modernmatchmaking.io",
    "https://beta.modernmatchmaking.io",
  ],
  config: {
    screens: config.reduce((acc, item: any) => {
      return {
        ...acc,
        [item.name]: item?.parse
          ? {
              path: item.link,
              parse: item.parse,
            }
          : item.link,
      };
    }, {}),
  },
};
