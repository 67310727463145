import { darkGrey, white } from "app/styles/theme";
import { ThemeContext } from "app/utils/context";
import * as React from "react";
import Svg, { G, Rect, Circle } from "react-native-svg";

const MenuOptions = () => {
  const { themes } = React.useContext(ThemeContext);
  return (
    <Svg viewBox="0 0 24 24" width={"25"} height={"25"}>
      <G data-name="Layer 2">
        <G data-name="more-vertical">
          <Rect
            width="24"
            height="24"
            transform="rotate(-90 12 12)"
            opacity="0"
          />
          <Circle
            fill={themes === "light" ? darkGrey : white}
            cx="12"
            cy="12"
            r="2"
          />
          <Circle
            fill={themes === "light" ? darkGrey : white}
            cx="12"
            cy="5"
            r="2"
          />
          <Circle
            fill={themes === "light" ? darkGrey : white}
            cx="12"
            cy="19"
            r="2"
          />
        </G>
      </G>
    </Svg>
  );
};

export default MenuOptions;
