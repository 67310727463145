import * as React from "react";

import { Layout } from "@ui-kitten/components";
import { StyleSheet } from "react-native";

import AudioCall from "app/components/audio/AudioCall";
import Scroll from "app/components/scroll/Scroll";
import { darkGrey, white } from "app/styles/theme";
import { fetchSubscription } from "app/utils/ajax";
import {
  AuthContext,
  ThemeContext,
  type ThemeContextProps,
  type AuthContextProps,
} from "app/utils/context";

const VirtualDate: React.FC<any> = (props) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;

  React.useLayoutEffect(() => {
    setRoute(props?.route?.name);
    // fetchSubscription().then((sub) => {
    //   if (!sub) {
    //     props?.navigation.navigate("Membership Setup");
    //   }
    // });
  }, [setRoute, props?.route?.name, props?.navigation]);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: themes === "light" ? white : darkGrey,
      })}
    >
      <Scroll>
        <AudioCall {...props} />
      </Scroll>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",

    flex: 1,
    justifyContent: "center",
  },
});

export default VirtualDate;
