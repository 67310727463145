/* eslint @typescript-eslint/no-var-requires: "off" */
import "react-native-gesture-handler";
import { registerRootComponent } from "expo";
import { AppRegistry } from "react-native-web";

import App from "./app/_layout.web";

registerRootComponent(App);
AppRegistry.runApplication("main", {
  rootTag: document.getElementById("root")
});
