import * as React from "react";

import { StyleSheet, SafeAreaView, ScrollView } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

const Scroll = ({ children, style }: any): any => {
  return (
    <SafeAreaView style={Object.assign({}, style, styles.container)}>
      <KeyboardAwareScrollView enableAutomaticScroll>
        <ScrollView
          contentContainerStyle={styles.contentContainer}
          style={styles.scrollView}
        >
          {children}
        </ScrollView>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    overflow: "hidden",
    position: "absolute",
    right: 0,
    top: 0,
    width: "100%",
  },
  contentContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  scrollView: {
    alignSelf: "center",
    borderRadius: 5,
    height: "20%",
    margin: 20,
    width: "100%",
  },
});

export default Scroll;
