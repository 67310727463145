import moment from "moment";
import "moment-timezone";

export const formatDateString = (ds: string): string => {
  const date = new Date(ds);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${year}-${month < 10 ? "0" + month : month}-${
    day < 10 ? "0" + day : day
  }`;
};

export const setDateString = (days = 0, months = 0, years = 0): string => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  date.setMonth(date.getMonth() + months);
  date.setFullYear(date.getFullYear() + years);
  return formatDateString(date.toISOString());
};

export const formatDateTime = (
  datetime: string,
  formatString: string
): string => {
  return moment(datetime).tz(getTimezone()).format(formatString);
};

export const LIST_OF_MONTHS = [
  "January",
  "Febuary",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Auguest",
  "September",
  "October",
  "November",
  "December",
];

export const formatToMonthYear = (datetime: string): string => {
  return moment(datetime).tz("America/Los_Angeles").format("MMM/YYYY");
};

export const formatMinutes = (datetime: string): string => {
  return moment(datetime).tz("America/Los_Angeles").format("hh:mm a");
};

export const formatHour = (datetime: string): string => {
  return moment(datetime).tz("America/Los_Angeles").format("hh:mm a");
};

export const formatDay = (datetime: string): string => {
  return moment(datetime).tz("America/Los_Angeles").format("dd Do");
};

export const formatWeek = (datetime: string): string => {
  return moment(datetime).tz("America/Los_Angeles").format("dd Do");
};

export const formatMonth = (datetime: string): string => {
  return formatToMonthYear(datetime);
};

export const formatYear = (datetime: string): string => {
  return formatToMonthYear(datetime);
};

export const formatYtd = (datetime: string): string => {
  return formatToMonthYear(datetime);
};

export const formatMax = (datetime: string): string => {
  return formatToMonthYear(datetime);
};

export const LIST_OF_PERIODS = [
  "1d",
  "5d",
  "1mo",
  "3mo",
  "6mo",
  "1y",
  "2y",
  "5y",
  "10y",
  "ytd",
  "max",
];

export const LIST_OF_INTERVALS = [
  "1m",
  "2m",
  "5m",
  "15m",
  "30m",
  "60m",
  "90m",
  "1h",
  "1d",
  "5d",
  "1wk",
  "1mo",
  "3mo",
];

export const ALL_INTERVALS = LIST_OF_INTERVALS.map((e) => ({
  title: e,
  id: e,
}));

export const ALL_PERIODS = LIST_OF_PERIODS.map((e) => ({ title: e, id: e }));

export const getTimezone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};
