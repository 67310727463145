import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Layout, Text, Button, Modal, List } from "@ui-kitten/components";
import axios from "axios";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import { darkGrey, white } from "app/styles/theme";
import { HOSTNAME } from "app/utils/ajax";
import {
  AuthContext,
  ThemeContext,
  type ThemeContextProps,
  type AuthContextProps,
} from "app/utils/context";
import Cannabis from "../preferences/Cannabis";
import Drinking from "../preferences/Drinking";
import EducationLevel from "../preferences/EducationLevel";
import Gender from "../preferences/Gender";
import HasChildren from "../preferences/HasChildren";
import Income from "../preferences/Income";
import MaritalStatus from "../preferences/MartialStatus";
import PoliticalViews from "../preferences/PoliticalViews";
import RacialBackground from "../preferences/RacialBackground";
import Religion from "../preferences/Religion";
import Smoking from "../preferences/Smoking";
import WantsChildren from "../preferences/WantsChildren";
import MaxAge from "../radios/MaxAge";
import MinAge from "../radios/MinAge";
import Scroll from "../scroll/Scroll";

const DatingPreferences: React.FC<any> = ({
  navigation,
  route,
  edit = false,
}) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;

  const [gender, setGender] = React.useState("");
  const [genderModal, setGenderModal] = React.useState(false);

  const [minAge, setMinAge] = React.useState("");
  const [minAgeModal, setMinAgeModal] = React.useState(false);

  const [maxAge, setMaxAge] = React.useState("");
  const [maxAgeModal, setMaxAgeModal] = React.useState(false);

  const [smoking, setSmoking] = React.useState("");
  const [smokingModal, setSmokingModal] = React.useState(false);

  const [cannabis, setCannabis] = React.useState("");
  const [cannabisModal, setCannabisModal] = React.useState(false);

  const [educationLevel, setEducationLevel] = React.useState("");
  const [educationLevelModal, setEducationLevelModal] = React.useState(false);

  const [politicalViews, setPoliticalViews] = React.useState("");
  const [politicalViewsModal, setPoliticalViewsModal] = React.useState(false);

  const [drinking, setDrinking] = React.useState("");
  const [drinkingModal, setDrinkingModal] = React.useState(false);

  const [hasChildren, setHasChildren] = React.useState("");
  const [hasChildrenModal, setHasChildrenModal] = React.useState(false);

  const [wantsChildren, setWantsChildren] = React.useState("");
  const [wantsChildrenModal, setWantsChildrenModal] = React.useState(false);

  const [maritalStatus, setMaritalStatus] = React.useState("");
  const [maritalStatusModal, setMaritalStatusModal] = React.useState(false);

  const [income, setIncome] = React.useState("");
  const [incomeModal, setIncomeModal] = React.useState(false);

  const [religion, setReligion] = React.useState("");
  const [religionModal, setReligionModal] = React.useState(false);

  const [racialBackground, setRacialBackground] = React.useState("");
  const [racialBackgroundModal, setRacialBackgroundModal] =
    React.useState(false);

  const fetchProfile = async () => {
    try {
      const token = await AsyncStorage.getItem("token");
      const response = await axios.get(
        `${HOSTNAME}/api/v1/user_dating_preferences`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = response?.data;
      const data = Array.isArray(res?.data) ? res?.data[0] : res?.data;

      setGender(data?.gender);
      setMinAge(data?.min_age);
      setMaxAge(data?.max_age);
      setEducationLevel(data?.education_level);
      setSmoking(data?.smoking);
      setCannabis(data?.cannabis);
      setPoliticalViews(data?.political_views);
      setDrinking(data?.drinking);
      setReligion(data?.religion);
      setIncome(data?.income);
      setMaritalStatus(data?.marital_status);
      setHasChildren(data?.has_children);
      setWantsChildren(data?.wants_children);
      setRacialBackground(data?.racial_background);
    } catch (error) {
      console.error(error);
    }
  };

  const LIST_OF_DATING_PREFERENCES = [
    {
      title: "Gender",
      onPress: () => {
        if (edit) setGenderModal(true);
      },
      value: gender,
    },
    {
      title: "Min Age",
      onPress: () => {
        if (edit) setMinAgeModal(true);
      },
      value: minAge,
    },
    {
      title: "Max Age",
      onPress: () => {
        if (edit) setMaxAgeModal(true);
      },
      value: maxAge,
    },
    {
      title: "Education Level",
      onPress: () => {
        if (edit) setEducationLevelModal(true);
      },
      value: educationLevel,
    },
    {
      title: "Smoking",
      onPress: () => {
        if (edit) setSmokingModal(true);
      },
      value: smoking,
    },
    {
      title: "Cannabis",
      onPress: () => {
        if (edit) setCannabisModal(true);
      },
      value: cannabis,
    },
    {
      title: "Political Views",
      onPress: () => {
        if (edit) setPoliticalViewsModal(true);
      },
      value: politicalViews,
    },
    {
      title: "Drinking",
      onPress: () => {
        if (edit) setDrinkingModal(true);
      },
      value: drinking,
    },
    {
      title: "Religion",
      onPress: () => {
        if (edit) setReligionModal(true);
      },
      value: religion,
    },
    {
      title: "Income",
      onPress: () => {
        if (edit) setIncomeModal(true);
      },
      value: income,
    },
    {
      title: "Marital Status",
      onPress: () => {
        if (edit) setMaritalStatusModal(true);
      },
      value: maritalStatus,
    },
    {
      title: "Has Children",
      onPress: () => {
        if (edit) setHasChildrenModal(true);
      },
      value: hasChildren,
    },
    {
      title: "Wants Children",
      onPress: () => {
        if (edit) setWantsChildrenModal(true);
      },
      value: wantsChildren,
    },
    {
      title: "Racial Background",
      onPress: () => {
        if (edit) setRacialBackgroundModal(true);
      },
      value: racialBackground,
    },
  ];

  React.useEffect(() => {
    fetchProfile();
  }, []);

  React.useLayoutEffect(() => {
    setRoute(route?.name);
  }, [route?.name, setRoute]);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: themes === "light" ? white : darkGrey,
      })}
    >
      {/* <Scroll> */}
      <List
        data={LIST_OF_DATING_PREFERENCES}
        contentContainerStyle={styles.column}
        renderItem={({ item, index }) => (
          <TouchableOpacity
            key={index}
            style={styles.card}
            onPress={() => {
              if (edit) item?.onPress();
            }}
          >
            <View style={styles.title}>
              <Text style={{ textAlign: "left" }}>{item.title}</Text>
            </View>
            <View style={styles.value}>
              <Text style={{ textAlign: "right" }}>{item?.value || "N/A"}</Text>
            </View>
          </TouchableOpacity>
        )}
      />
      <Button
        onPress={async () => {
          await fetchProfile();
        }}
      >
        Refresh
      </Button>
      {!!gender && !!edit && (
        <Button onPress={() => navigation.navigate("Location Setup")}>
          Next
        </Button>
      )}
      {/* </Scroll> */}
      <Modal backdropStyle={styles.backdrop} visible={genderModal}>
        <Gender edit />
        <Button
          onPress={() => {
            setGenderModal(false);
          }}
        >
          Close
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={minAgeModal}>
        <MinAge edit />
        <Button
          onPress={() => {
            setMinAgeModal(false);
          }}
        >
          Close
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={maxAgeModal}>
        <MaxAge edit />
        <Button
          onPress={() => {
            setMaxAgeModal(false);
          }}
        >
          Close
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={educationLevelModal}>
        <EducationLevel edit />
        <Button
          onPress={() => {
            setEducationLevelModal(false);
          }}
        >
          Close
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={smokingModal}>
        <Smoking edit />
        <Button
          onPress={() => {
            setSmokingModal(false);
          }}
        >
          Close
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={cannabisModal}>
        <Cannabis edit />
        <Button
          onPress={() => {
            setCannabisModal(false);
          }}
        >
          Close
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={politicalViewsModal}>
        <PoliticalViews edit />
        <Button
          onPress={() => {
            setPoliticalViewsModal(false);
          }}
        >
          Close
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={drinkingModal}>
        <Drinking edit />
        <Button
          onPress={() => {
            setDrinkingModal(false);
          }}
        >
          Close
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={religionModal}>
        <Religion edit />
        <Button
          onPress={() => {
            setReligionModal(false);
          }}
        >
          Close
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={incomeModal}>
        <Income edit />
        <Button
          onPress={() => {
            setIncomeModal(false);
          }}
        >
          Close
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={maritalStatusModal}>
        <MaritalStatus edit />
        <Button
          onPress={() => {
            setMaritalStatusModal(false);
          }}
        >
          Close
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={hasChildrenModal}>
        <HasChildren edit />
        <Button
          onPress={() => {
            setHasChildrenModal(false);
          }}
        >
          Close
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={wantsChildrenModal}>
        <WantsChildren edit />
        <Button
          onPress={() => {
            setWantsChildrenModal(false);
          }}
        >
          Close
        </Button>
      </Modal>
      <Modal backdropStyle={styles.backdrop} visible={racialBackgroundModal}>
        <RacialBackground edit />
        <Button
          onPress={() => {
            setRacialBackgroundModal(false);
          }}
        >
          Close
        </Button>
      </Modal>
    </Layout>
  );
};

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  card: {
    alignItems: "center",
    borderColor: "grey",
    borderRadius: 5,
    borderWidth: 1,
    display: "flex",
    flexDirection: "row",
    height: 50,
    justifyContent: "space-between",
    padding: 10,
    width: "100%",
  },
  column: {
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    width: "100%",
  },
  container: {
    alignContent: "center",
    flexDirection: "column",
    flex: 1,
    height: "100%",
    justifyContent: "center",
    paddingHorizontal: 40,
    width: "100%",
  },
  title: {
    display: "flex",
    fontSize: 20,
    fontWeight: "bold",
    height: "100%",
    justifyContent: "center",
    width: "25%",
  },
  value: {
    color: "grey",
    display: "flex",
    fontSize: 20,
    height: "100%",
    justifyContent: "center",
    width: "50%",
  },
});

export default DatingPreferences;
