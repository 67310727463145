import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  Text,
  View,
  StyleSheet,
  Pressable,
  Platform,
  useWindowDimensions,
} from "react-native";
import Svg, { Rect } from "react-native-svg";

import { darkGrey, mdBreakPoint, primary, white } from "app/styles/theme";
import {
  AuthContext,
  ThemeContext,
  type ThemeContextProps,
  type AuthContextProps,
} from "app/utils/context";

export const HeaderLeft: React.FC<any> = ({ navigation }) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;
  const { width } = useWindowDimensions();
  const [token, setToken] = React.useState<string>("");

  React.useEffect(() => {
    const getToken = async () => {
      const token = await AsyncStorage.getItem("token");
      setToken(token ?? "");
    };
    getToken().catch(console.error);
  }, [token]);

  return (
    <View style={styles.background}>
      <Pressable
        onPress={() => {
          setRoute(token ? "Home" : "Index");
          navigation.navigate(token ? "Home" : "Index");
        }}
      >
        <Text
          style={Object.assign({}, styles.text, {
            color: themes === "light" ? white : darkGrey,
          })}
        >
          {token ? "Dashboard" : "Home"}
        </Text>
      </Pressable>
      {/* {Platform.OS === "web" && width >= mdBreakPoint ? (
        <Pressable
          onPress={() => {
            setRoute("Pricing");
            navigation.navigate("Pricing");
          }}
        >
          <Text
            style={Object.assign({}, styles.text, {
              color: themes === "light" ? white : darkGrey,
            })}
          >
            Pricing
          </Text>
        </Pressable>
      ) : (
        <></>
      )} */}
      {Platform.OS === "web" && width >= mdBreakPoint ? (
        <Pressable
          onPress={() => {
            setRoute("About");
            navigation.navigate("About");
          }}
        >
          <Text
            style={Object.assign({}, styles.text, {
              color: themes === "light" ? white : darkGrey,
            })}
          >
            About
          </Text>
        </Pressable>
      ) : (
        <></>
      )}
      {Platform.OS === "web" && width >= mdBreakPoint ? (
        <Pressable
          onPress={() => {
            setRoute("Contact");
            navigation.navigate("Contact");
          }}
        >
          <Text
            style={Object.assign({}, styles.text, {
              color: themes === "light" ? white : darkGrey,
            })}
          >
            Contact
          </Text>
        </Pressable>
      ) : (
        <></>
      )}
      {Platform.OS === "web" && width >= mdBreakPoint ? (
        <Pressable
          onPress={() => {
            setRoute("Private Policy");
            navigation.navigate("Private Policy");
          }}
        >
          <Text
            style={Object.assign({}, styles.text, {
              color: themes === "light" ? white : darkGrey,
            })}
          >
            Private Policy
          </Text>
        </Pressable>
      ) : (
        <></>
      )}
      {Platform.OS === "web" && width >= mdBreakPoint ? (
        <Pressable
          onPress={() => {
            setRoute("Terms & Conditions");
            navigation.navigate("Terms & Conditions");
          }}
        >
          <Text
            style={Object.assign({}, styles.text, {
              color: themes === "light" ? white : darkGrey,
            })}
          >
            Terms & Conditions
          </Text>
        </Pressable>
      ) : (
        <></>
      )}
    </View>
  );
};

export const HeaderTwoAuthRight = () => {
  const { themes, setThemes }: ThemeContextProps = React.useContext(
    ThemeContext
  ) as ThemeContextProps;
  return (
    <View style={styles.background}>
      <Pressable
        onPress={() => {
          const theme = themes === "light" ? "dark" : "light";
          setThemes(theme);
        }}
      >
        <Text
          style={Object.assign({}, styles.text, {
            color: themes === "light" ? white : darkGrey,
          })}
        >
          {themes === "light" ? "Dark Mode" : "Light Mode"}
        </Text>
      </Pressable>
    </View>
  );
};

export const HeaderRight: React.FC<any> = ({ navigation, route }) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const { themes, setThemes }: ThemeContextProps = React.useContext(
    ThemeContext
  ) as ThemeContextProps;
  return (
    <View style={styles.background}>
      <Pressable
        onPress={() => {
          const theme = themes === "light" ? "dark" : "light";
          setThemes(theme);
        }}
      >
        <Text
          style={Object.assign({}, styles.text, {
            color: themes === "light" ? white : darkGrey,
          })}
        >
          {themes === "light" ? "Dark Mode" : "Light Mode"}
        </Text>
      </Pressable>
      <Pressable
        onPress={() => {
          setRoute("Login");
          navigation.navigate("Login");
        }}
      >
        {route?.name !== "Login" && (
          <Text
            style={Object.assign({}, styles.text, {
              color: themes === "light" ? white : darkGrey,
            })}
          >
            Login
          </Text>
        )}
      </Pressable>
      <Pressable
        onPress={() => {
          setRoute("Sign Up");
          navigation.navigate("Sign Up");
        }}
      >
        {route?.name === "Login" && (
          <Text
            style={Object.assign({}, styles.text, {
              color: themes === "light" ? white : darkGrey,
            })}
          >
            Sign Up
          </Text>
        )}
      </Pressable>
    </View>
  );
};

export const HeaderAuthLeft: React.FC<any> = ({ navigation, route }) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;

  const menuOn = (): void => {
    navigation.push("Menu");
  };
  const menuOff = (): void => {
    navigation.pop();
  };

  return (
    <View style={styles.background}>
      <Pressable onPress={route?.name === "Menu" ? menuOff : menuOn}>
        <Svg
          color={themes === "light" ? white : darkGrey}
          viewBox="0 0 100 80"
          width="25"
          height="25"
        >
          <Rect
            fill={themes === "light" ? white : darkGrey}
            width="100"
            height="15"
          ></Rect>
          <Rect
            fill={themes === "light" ? white : darkGrey}
            y="30"
            width="100"
            height="15"
          ></Rect>
          <Rect
            fill={themes === "light" ? white : darkGrey}
            y="60"
            width="100"
            height="15"
          ></Rect>
        </Svg>
      </Pressable>
      <Pressable
        onPress={() => {
          setRoute("Home");
          navigation.navigate("Home");
        }}
      >
        <Text
          style={Object.assign({}, styles.text, {
            color: themes === "light" ? white : darkGrey,
          })}
        >
          Home
        </Text>
      </Pressable>
    </View>
  );
};

export const HeaderTwoWayAuthLeft: React.FC<any> = ({ navigation }) => {
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;
  return (
    <View style={styles.background}>
      <Pressable
        onPress={() => {
          navigation.goBack();
        }}
      >
        <Text
          style={Object.assign({}, styles.text, {
            color: themes === "light" ? white : darkGrey,
          })}
        >
          Back
        </Text>
      </Pressable>
    </View>
  );
};

export const HeaderAuthRight: React.FC<any> = ({ navigation }) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const { themes, setThemes } = React.useContext(
    ThemeContext
  ) as ThemeContextProps;

  const loggingOut = async (): Promise<void> => {
    await AsyncStorage.multiRemove([
      "token",
      "phoneNumber",
      "isActive",
      "deletedAt",
    ]);
    setRoute("Login");
    navigation.push("Login");
  };

  return (
    <View style={styles.background}>
      <Pressable
        onPress={() => {
          const theme = themes === "light" ? "dark" : "light";
          setThemes(theme);
        }}
      >
        <Text
          style={Object.assign({}, styles.text, {
            color: themes === "light" ? white : darkGrey,
          })}
        >
          {themes === "light" ? "Dark Mode" : "Light Mode"}
        </Text>
      </Pressable>
      <Pressable
        onPress={() => {
          loggingOut().catch(console.error);
        }}
      >
        <Text
          style={Object.assign({}, styles.text, {
            color: themes === "light" ? white : darkGrey,
          })}
        >
          Logout
        </Text>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  background: {
    backgroundColor: primary,

    flexDirection: "row",
  },
  text: {
    paddingLeft: 20,
    paddingRight: 20,
  },
});
