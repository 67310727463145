import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Layout, Card, Input, Button, Text } from "@ui-kitten/components";
import axios from "axios";
import { StyleSheet } from "react-native";

import { HOSTNAME } from "app/utils/ajax";
import {
  AuthContext,
  ThemeContext,
  type ThemeContextProps,
  type AuthContextProps,
} from "app/utils/context";

const NamePlate: React.FC<any> = ({ navigation, route, edit = false }) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;

  const fetchProfile = async () => {
    try {
      const token = await AsyncStorage.getItem("token");
      const id = route?.params?.user_match_id || route?.params?.id;
      const response = await axios.get(
        `${HOSTNAME}/api/v1/user_profile${id ? "/" + id : ""}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = response?.data;
      const data = Array.isArray(res?.data) ? res?.data[0] : res?.data;
      setFirstName(data?.first_name);
    } catch (error) {
      console.error(error);
    }
  };

  const setName = async () => {
    try {
      const token = await AsyncStorage.getItem("token");
      const response = await axios.put(
        `${HOSTNAME}/api/v1/user_profile`,
        { first_name: firstName },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = response?.data;
      setFirstName(res?.data[0]?.first_name);
    } catch (error) {
      console.error(error);
    }
  };

  const [firstName, setFirstName] = React.useState("");

  React.useLayoutEffect(() => {
    setRoute(route?.name);
  }, [setRoute, route?.name]);

  React.useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <Layout>
      <Card>
        {edit && (
          <Input
            placeholder="First Name"
            style={styles.input}
            value={firstName}
            onChangeText={(text) => {
              setFirstName(text);
            }}
          />
        )}
        {!edit && <Text category="h2">{firstName}</Text>}
        {edit && (
          <Button
            onPress={async () => {
              await setName();
            }}
          >
            Save
          </Button>
        )}
      </Card>
    </Layout>
  );
};

const styles = StyleSheet.create({
  input: {
    width: "100%",
  },
});

export default NamePlate;
