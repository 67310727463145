import * as React from "react";

import Svg, { Path, G, Rect } from "react-native-svg";

const Deny = (props: any) => {
  return (
    <Svg {...props} viewBox="0 0 24 24">
      <G data-name="Layer 2">
        <G data-name="close-circle">
          <Rect width="24" height="24" opacity="0" />
          <Path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z" />
          <Path d="M14.71 9.29a1 1 0 0 0-1.42 0L12 10.59l-1.29-1.3a1 1 0 0 0-1.42 1.42l1.3 1.29-1.3 1.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l1.29-1.3 1.29 1.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42L13.41 12l1.3-1.29a1 1 0 0 0 0-1.42z" />
        </G>
      </G>
    </Svg>
  );
};

export default Deny;
