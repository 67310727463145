import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Button, Layout, Text } from "@ui-kitten/components";
import axios from "axios";
import { StyleSheet, View } from "react-native";
import { DatePickerInput } from "react-native-paper-dates";

import { darkGrey, white } from "app/styles/theme";
import { HOSTNAME } from "app/utils/ajax";
import { ThemeContext, type ThemeContextProps } from "app/utils/context";

const Age = ({ navigation, edit = false }: any) => {
  const [date, setDate]: any = React.useState(undefined);
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;

  const isOlderThan18 = (date: Date) => {
    const today = new Date();
    let age = today.getFullYear() - date.getFullYear();
    const m = today.getMonth() - date.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
      age--;
    }
    return age >= 18;
  };

  React.useEffect(() => {
    const fetchAge = async () => {
      try {
        const token = await AsyncStorage.getItem("token");
        const { data } = await axios.get(`${HOSTNAME}/api/v1/user_profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        setDate(new Date(data?.data[0]?.age));
      } catch (error) {
        console.error(error);
      }
    };

    fetchAge().catch(console.error);
  }, []);

  const updateAge = async (age: Date) => {
    if (!isOlderThan18(age)) {
      return;
    }

    try {
      const token = await AsyncStorage.getItem("token");
      const { data } = await axios.put(
        `${HOSTNAME}/api/v1/user_profile`,
        {
          age,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setDate(new Date(data.data.age));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: themes === "light" ? white : darkGrey,
      })}
    >
      <View style={styles.subContainer}>
        <Text category="h1" style={{ textAlign: "center" }}>
          Age
        </Text>
        <View style={{ width: "80%" }}>
          <DatePickerInput
            locale="en"
            label="Date of Birth"
            inputMode="start"
            value={date}
            onChange={(e: any) => {
              updateAge(e).catch(console.error);
            }}
          />
        </View>
        {!!date && !!edit && isOlderThan18(date) && (
          <Button onPress={() => navigation.navigate("Personal Details Setup")}>
            Next
          </Button>
        )}
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "flex-start",
    width: "100%",
  },
  subContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "flex-start",
    width: "100%",
  },
});

export default Age;
