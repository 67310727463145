import * as React from "react";

import { type HeaderBackButtonProps } from "@react-navigation/native-stack/lib/typescript/src/types";

import { darkGrey, primary, white } from "../../styles/theme";

import {
  HeaderAuthLeft,
  HeaderAuthRight,
  HeaderLeft,
  HeaderRight,
  HeaderTwoWayAuthLeft,
  HeaderTwoAuthRight,
} from "./Header";

export const homeOptions = (props: any): any => ({
  title: "",
  headerStyle: {
    backgroundColor: primary,
    color: props?.themes === "light" ? white : darkGrey,
  },
  headerLeft: (innerProps: HeaderBackButtonProps) => (
    <HeaderLeft {...props} {...innerProps} />
  ),
  headerRight: (innerProps: HeaderBackButtonProps) => (
    <HeaderRight {...props} {...innerProps} />
  ),
});

export const authOptions = (props: any): any => ({
  title: "",
  headerStyle: {
    backgroundColor: primary,
    color: props?.themes === "light" ? white : darkGrey,
  },
  headerLeft: (innerProps: HeaderBackButtonProps) => (
    <HeaderAuthLeft {...props} {...innerProps} />
  ),
  headerRight: (innerProps: HeaderBackButtonProps) => (
    <HeaderAuthRight {...props} {...innerProps} />
  ),
});

export const twoWayAuthOptions = (props: any): any => ({
  title: "",
  headerStyle: {
    backgroundColor: primary,
    color: props?.themes === "light" ? white : darkGrey,
  },
  headerLeft: (innerProps: HeaderBackButtonProps) => (
    <HeaderTwoWayAuthLeft {...props} {...innerProps} />
  ),
  headerRight: (innerProps: HeaderBackButtonProps) => (
    <HeaderTwoAuthRight {...props} {...innerProps} />
  ),
});
