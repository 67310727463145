import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Layout, Text, Card, Button } from "@ui-kitten/components";
import axios from "axios";
import { Platform, StyleSheet } from "react-native";

import Scroll from "app/components/scroll/Scroll";
import { darkGrey, white } from "app/styles/theme";
import { HOSTNAME } from "app/utils/ajax";
import {
  AuthContext,
  ThemeContext,
  type ThemeContextProps,
  type AuthContextProps,
} from "app/utils/context";

const Account: React.FC<any> = ({ navigation, route }) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;

  const [loading, setLoading] = React.useState(true);
  const [subscription, setSubscription]: any = React.useState({});
  const [data, setData] = React.useState({});

  const fetchData = async () => {
    setLoading(true);
    try {
      const token = await AsyncStorage.getItem("token");
      const { data } = await axios.get(`${HOSTNAME}/api/v1/fetch_all_data`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setData(data || {});
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const cancelData = async () => {
    setLoading(true);
    try {
      const token = await AsyncStorage.getItem("token");
      await axios.delete(`${HOSTNAME}/api/v1/clear_all_data`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setData({});
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  // const fetchSubscription = async () => {
  //   setLoading(true);
  //   try {
  //     const token = await AsyncStorage.getItem("token");
  //     const response = await axios.get(`${HOSTNAME}/api/v1/user_subscription`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     const data = response?.data;
  //     setSubscription(data?.data || {});
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   setLoading(false);
  // };

  // const cancelSubscription = async () => {
  //   setLoading(true);
  //   try {
  //     const token = await AsyncStorage.getItem("token");
  //     await axios.delete(
  //       `${HOSTNAME}/api/v1/subscriptions/${subscription?.id}`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     setSubscription({});
  //     await AsyncStorage.removeItem("subscription");
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   setLoading(false);
  // };

  const downloadJsonFile = async () => {
    try {
      if (!data) {
        return;
      }

      if (Platform.OS !== "web") {
        const { DocumentDirectoryPath, writeFile } = require("react-native-fs");
        await writeFile(
          `${DocumentDirectoryPath}/data.json`,
          JSON.stringify(data),
          "utf8"
        );
      } else if (typeof document !== "undefined") {
        const element = document?.createElement("a");
        const file = new Blob([JSON.stringify(data)], {
          type: "application/json",
        });
        element.href = URL.createObjectURL(file);
        element.download = "data.json";
        document?.body?.appendChild(element);
        element.click();
      }
    } catch (error) {
      console.error(error);
    }
  };

  React.useLayoutEffect(() => {
    fetchData();
    // fetchSubscription();
    setRoute(route?.name);
  }, [setRoute, route?.name]);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: themes === "light" ? white : darkGrey,
      })}
    >
      <Scroll>
        <Text category="h1">Account</Text>
        {/* <Card>
          <Text>Current Subscription</Text>
          <Text>{subscription?.id}</Text>
          <Text>{subscription?.price}</Text>
          <Text>{subscription?.next_billing_date}</Text>
          <Text>{subscription?.status}</Text>
          <Button
            onPress={async () => {
              await cancelSubscription();
            }}
          >
            <Text>Cancel Subscription</Text>
          </Button>
        </Card> */}
        <Card>
          <Text>Current Data</Text>
          <Text>{JSON.stringify(data)}</Text>
          <Button
            onPress={async () => {
              await downloadJsonFile();
            }}
          >
            <Text>Download Data</Text>
          </Button>
          <Button
            onPress={async () => {
              await cancelData();
            }}
          >
            <Text>Clear Data</Text>
          </Button>
        </Card>
      </Scroll>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",

    flex: 1,
    justifyContent: "center",
  },
});

export default Account;
