export const primary = "#6B63FF";
export const white = "#FAFAFA";
export const lightGrey = "#ebebeb";
export const grey = "#E6E6E6";
export const darkGrey = "#2F2E41";
export const black = "#303030";
export const red = "#C61A09";
export const green = "#00B300";
const ratio = 150;
export const maxWidth = 6 * ratio;
export const maxHeight = 9.5 * ratio;
export const mdBreakPoint = 720;
