import * as React from "react";

import { Svg, Path } from "react-native-svg";

const CalendarIcon = (props: any) => {
  return (
    <Svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
      <Path
        d="M20 4H18V2H16V4H8V2H6V4H4C2.9 4 2.01 4.9 2.01 6L2 20C2 21.1 2.9 22 4 22H20C21.1 22 22 21.1 22 20V6C22 4.9 21.1 4 20 4ZM20 20H4V10H20V20Z"
        fill="#FFC107"
      />
    </Svg>
  );
};

export default CalendarIcon;
