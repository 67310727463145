import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Layout, Card, Radio, RadioGroup, Text } from "@ui-kitten/components";
import axios from "axios";
import { StyleSheet } from "react-native";

import { HOSTNAME } from "app/utils/ajax";
import {
  AuthContext,
  ThemeContext,
  type ThemeContextProps,
  type AuthContextProps,
} from "app/utils/context";

const LIST = [
  "High School",
  "In College",
  "Associate's Degree",
  "Bachelor's Degree",
  "Master's Degree",
  "Doctorate's Degree",
];

const EducationLevel: React.FC<any> = ({ navigation, route, edit = false }) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;

  const [educationLevel, setEducationLevel] = React.useState(0);

  const getOptions = (index: number) => {
    return LIST[index];
  };

  const getIndex = (item: any) => {
    return LIST.findIndex((element) => element === item);
  };

  const fetchProfile = async () => {
    try {
      const token = await AsyncStorage.getItem("token");
      const response = await axios.get(`${HOSTNAME}/api/v1/user_profile`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const res = response?.data;
      setEducationLevel(getIndex(res?.data[0]?.education_level));
    } catch (error) {
      console.error(error);
    }
  };

  const setEducation = async (index: number) => {
    try {
      const token = await AsyncStorage.getItem("token");
      const response = await axios.put(
        `${HOSTNAME}/api/v1/user_profile`,
        { education_level: getOptions(index) },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = response?.data;
      setEducationLevel(getIndex(res?.data[0]?.education_level));
    } catch (error) {
      console.error(error);
    }
  };

  React.useLayoutEffect(() => {
    setRoute(route?.name);
  }, [setRoute, route?.name]);

  React.useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <Layout>
      <Card>
        <Text>Education Level</Text>
        <RadioGroup
          selectedIndex={educationLevel}
          onChange={async (index) => {
            await setEducation(index);
          }}
        >
          {LIST.map((item, index) => (
            <Radio key={index}>{item}</Radio>
          ))}
        </RadioGroup>
      </Card>
    </Layout>
  );
};

const styles = StyleSheet.create({
  input: {
    width: "100%",
  },
});

export default EducationLevel;
