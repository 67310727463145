import * as React from "react";

import { Layout } from "@ui-kitten/components";
import { StyleSheet } from "react-native";

import { darkGrey, primary, white } from "app/styles/theme";
import { ThemeContext, type ThemeContextProps } from "app/utils/context";
import Gallery from "../images/Gallery";
import AboutMe from "../texts/AboutMe";
import LookingFor from "../texts/LookingFor";
import NamePlate from "../texts/NamePlate";
import Scroll from "../scroll/Scroll";

const Profile: React.FC<any> = (props) => {
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;
  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: themes === "light" ? white : darkGrey,
      })}
    >
      <Scroll>
        <NamePlate {...props} />
        <Gallery {...props} />
        <AboutMe {...props} />
        <LookingFor {...props} />
      </Scroll>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",

    justifyContent: "center",
  },
  icon: {
    color: primary,
    height: 32,
    width: 32,
  },
  input: {
    width: "100%",
  },
  row: {
    flexDirection: "row",
  },
});

export default Profile;
