import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Layout, Input, Text } from "@ui-kitten/components";
import axios from "axios";
import {
  StyleSheet,
  Platform,
  View,
  Pressable,
  useWindowDimensions,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import ErrorMessage from "app/components/errors/Message";
import Loader from "app/components/loaders/Spinner";
import {
  black,
  grey,
  maxHeight,
  maxWidth,
  primary,
  red,
  darkGrey,
  white,
} from "app/styles/theme";
import { HOSTNAME, redirectToHomepageWhenExpired } from "app/utils/ajax";
import {
  AuthContext,
  ThemeContext,
  type ThemeContextProps,
  type AuthContextProps,
} from "app/utils/context";

const ResetPassword: React.FC<any> = ({ navigation, route }) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;
  const layout = useWindowDimensions();
  const width = layout.width > maxWidth ? maxWidth : layout.width;
  const height = layout.height > maxHeight ? maxHeight : layout.height;

  const title = { fontSize: width * 0.1 };
  const buttonText = {
    fontSize: width * (Platform.OS === "web" ? 0.025 : 0.05),
  };
  const subContainer = {
    width: width * 0.8,
    height: height * 0.8,
  };

  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const resetErrorMessage = (): void => {
    setTimeout(() => {
      setError("");
    }, 3000);
  };

  const onSubmit = async (): Promise<void> => {
    try {
      setLoading(true);
      const token = await AsyncStorage.getItem("token");
      const phone = await AsyncStorage.getItem("phoneNumber");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const reqBody = {
        phone_number: phone,
        password,
        confirm_password: confirmPassword,
      };
      const res = await axios.post(
        `${HOSTNAME}/api/v1/forgotten_password`,
        reqBody,
        {
          headers,
        }
      );
      if (res?.data?.error) {
        setError(res?.data?.error);
        resetErrorMessage();
      } else {
        setError("");
      }
      if (res?.data?.access_token) {
        await AsyncStorage.setItem("token", String(res?.data?.access_token));
        await AsyncStorage.setItem(
          "isActive",
          String(Boolean(res?.data?.is_active))
        );
        const page = res?.data?.is_active ? "Redirect Home" : "Profile Setup";
        setRoute(page);
        navigation.navigate(page);
      }
    } catch (error: any) {
      setError(error?.response?.data?.error);
      resetErrorMessage();
      await redirectToHomepageWhenExpired(error, navigation, setRoute);
    }
    setLoading(false);
  };

  React.useLayoutEffect(() => {
    setRoute(route?.name);
  }, [setRoute, route?.name]);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: themes === "light" ? white : darkGrey,
      })}
    >
      <KeyboardAwareScrollView enableAutomaticScroll>
        <View style={Object.assign({}, styles.subContainer, subContainer)}>
          <View style={styles.titleContainer}>
            <Text style={Object.assign({}, title, styles.title)}>
              Reset Password
            </Text>
          </View>
          <View style={styles.keyboardContainer}>
            <View
              style={Object.assign({}, styles.textFieldContainer, {
                color: themes === "light" ? darkGrey : white,
              })}
            >
              <Input
                secureTextEntry
                placeholder="Enter password"
                style={Object.assign({}, styles.textField, {
                  color: themes === "light" ? darkGrey : white,
                })}
                onChangeText={setPassword}
                maxLength={20}
              />
            </View>
            <View
              style={Object.assign({}, styles.textFieldContainer, {
                color: themes === "light" ? darkGrey : white,
              })}
            >
              <Input
                secureTextEntry
                placeholder="Confirm password"
                style={Object.assign({}, styles.textField, {
                  color: themes === "light" ? darkGrey : white,
                })}
                onChangeText={setConfirmPassword}
                maxLength={20}
              />
            </View>
          </View>
        </View>
        {loading && <Loader />}
        {error?.length > 0 && <ErrorMessage message={error} color={red} />}
        <Pressable
          disabled={loading}
          style={Object.assign({}, styles.buttonContainer, {
            color: themes === "light" ? darkGrey : white,
          })}
          onPress={() => {
            onSubmit().catch(console.error);
          }}
        >
          <View>
            <Text
              style={Object.assign(
                {},
                buttonText,
                Object.assign({}, styles.buttonText, {
                  color: themes === "light" ? darkGrey : white,
                })
              )}
            >
              Reset Password
            </Text>
          </View>
        </Pressable>
      </KeyboardAwareScrollView>
    </Layout>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    alignItems: "center",
    backgroundColor: primary,
    borderRadius: 50,

    display: "flex",
    height: Platform.OS === "web" ? "5%" : "7.5%",
    justifyContent: "center",
    margin: 0,
    padding: 0,
    width: "100%",
  },
  buttonText: {
    alignItems: "center",
    backgroundColor: primary,

    display: "flex",
    justifyContent: "center",
    margin: 0,
    padding: 0,
  },
  container: {
    alignItems: "center",

    display: "flex",
    flex: 1,
    height: "100%",
    justifyContent: "center",
    margin: 0,
    padding: 0,
    width: "100%",
  },
  keyboardContainer: {
    alignItems: "center",

    display: "flex",
    flexDirection: "column",
    height: "auto",
    justifyContent: "center",
    marginVertical: 60,
    width: "100%",
  },
  subContainer: {
    alignItems: "center",

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: 0,
    padding: 0,
  },
  textField: {
    alignItems: "center",
    borderColor: grey,
    borderRadius: 50,
    borderWidth: 2,
    color: black,
    display: "flex",
    height: 50,
    justifyContent: "center",
    paddingLeft: 20,
    paddingRight: 20,
    width: "100%",
  },
  textFieldContainer: {
    alignItems: "center",
    borderColor: grey,
    borderRadius: 50,
    borderWidth: 2,
    color: black,
    display: "flex",
    flexDirection: "row",
    height: 50,
    justifyContent: "center",
    margin: 20,
    padding: 0,
    width: "100%",
  },
  title: {
    alignItems: "center",
    color: primary,
    display: "flex",
    fontFamily: "Futura",
    height: Platform.OS === "web" ? "5%" : "auto",
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
  },
  titleContainer: {
    alignItems: "center",

    display: "flex",
    height: Platform.OS === "web" ? "5%" : "auto",
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
  },
});

export default ResetPassword;
