import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Layout, Card, Radio, Text, RadioGroup } from "@ui-kitten/components";
import axios from "axios";
import { View, StyleSheet } from "react-native";

import { HOSTNAME } from "../../utils/ajax";

const LIST = [
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
  "60",
  "61",
  "62",
  "63",
  "64",
  "65",
  "66",
  "67",
  "68",
  "69",
  "70",
  "71",
  "72",
  "73",
  "74",
  "75",
  "76",
  "77",
  "78",
  "79",
  "80",
  "81",
  "82",
  "83",
  "84",
  "85",
  "86",
  "87",
  "88",
  "89",
  "90",
  "91",
  "92",
  "93",
  "94",
  "95",
  "96",
  "97",
  "98",
  "99",
  "100",
];

const MaxAge = (props: any) => {
  const [max_age, setMaxAge] = React.useState(100);

  const setMaxAges = async (age: number) => {
    try {
      const token = await AsyncStorage.getItem("token");
      const response = await axios.put(
        `${HOSTNAME}/api/v1/user_dating_preferences`,
        { max_age: age },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = response?.data;
      setMaxAge(getIndex(res?.data[0]?.max_age));
    } catch (error) {
      console.error(error);
    }
  };

  const getOptions = (index: number) => {
    return LIST[index];
  };

  const getIndex = (item: any) => {
    return LIST.findIndex((element) => element === item);
  };

  return (
    <Layout>
      <Card>
        <Text>Maximum Age</Text>
        <RadioGroup
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
          }}
          onChange={async (index) => {
            await setMaxAges(Number(getOptions(index)));
          }}
        >
          {LIST.map((item, index) => (
            <Radio key={index} checked={Number(max_age) === Number(item)}>
              {Number(item)}
            </Radio>
          ))}
        </RadioGroup>
      </Card>
    </Layout>
  );
};

export default MaxAge;
