import * as React from "react";

import { Layout } from "@ui-kitten/components";
import { StyleSheet } from "react-native";

import { default as UserPersonalDetails } from "app/components/pages/PersonalDetails";
import { darkGrey, white } from "app/styles/theme";
import {
  AuthContext,
  ThemeContext,
  type ThemeContextProps,
  type AuthContextProps,
} from "app/utils/context";
import Scroll from "app/components/scroll/Scroll";

const PersonalDetailsSetup: React.FC<any> = (props) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;

  React.useLayoutEffect(() => {
    setRoute(props?.route?.name);
  }, [setRoute, props?.route?.name, props.navigation]);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: themes === "light" ? white : darkGrey,
      })}
    >
      <Scroll>
        <UserPersonalDetails {...props} edit />
      </Scroll>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",

    flex: 1,
    justifyContent: "center",
  },
});

export default PersonalDetailsSetup;
