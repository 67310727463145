import * as React from "react";

import { Layout, Text } from "@ui-kitten/components";
import { StyleSheet, View } from "react-native";
import Hyperlink from "react-native-hyperlink";

import Membership from "app/components/pages/Membership";
import VideoBackground from "app/components/video/VideoBackground";
import { darkGrey, primary, white } from "app/styles/theme";
import {
  AuthContext,
  ThemeContext,
  type ThemeContextProps,
  type AuthContextProps,
} from "app/utils/context";

// @ts-expect-error import/first
const Link = Hyperlink?.default ? Hyperlink.default : Hyperlink;

const Pricing: React.FC<any> = ({ route }) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;

  React.useLayoutEffect(() => {
    setRoute(route?.name);
  }, [setRoute, route?.name]);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: themes === "light" ? white : darkGrey,
      })}
    >
      <VideoBackground style={styles.videoBackground}>
        <View style={styles.subContainer}>
          <View style={styles.titleContianer}>
            <Text style={styles.titleText}>Pricing</Text>
          </View>
          <Membership />
        </View>
      </VideoBackground>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  subContainer: {
    alignItems: "center",

    flexDirection: "column",
    justifyContent: "flex-start",
  },
  titleContianer: {
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
  titleText: {
    color: primary,
    fontSize: 48,
  },
  videoBackground: {
    minHeight: "100%",
    minWidth: "100%",
    position: "absolute",
  },
});

export default Pricing;
