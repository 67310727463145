import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Link } from "@react-navigation/native";
import { Card, Layout, List, Modal, Text } from "@ui-kitten/components";
import axios from "axios";
import { StyleSheet, Image, TouchableOpacity, View } from "react-native";
import WebView from "react-native-webview";

import Accept from "app/components/icons/Accept";
import Calendar from "app/components/icons/Calendar";
import Deny from "app/components/icons/Deny";
import Pending from "app/components/icons/Pending";
import Loader from "app/components/loaders/Spinner";
import Scroll from "app/components/scroll/Scroll";
import { darkGrey, primary, white } from "app/styles/theme";
import { fetchSubscription, HOSTNAME } from "app/utils/ajax";
import {
  AuthContext,
  ThemeContext,
  type ThemeContextProps,
  type AuthContextProps,
} from "app/utils/context";
import Options from "app/components/popups/Options";
import { SQUARE_APP_ID, SQUARE_LOCATION_ID } from "app/services/Constants";

const match = [
  {
    amount: 9.99,
    num_of_matches: 5,
  },
  {
    amount: 24.99,
    num_of_matches: 15,
  },
  {
    amount: 49.99,
    num_of_matches: 30,
  },
];

const Dashboard: React.FC<any> = ({ navigation, route }) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;

  const [userId, setUserId] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [matches, setMatches] = React.useState([]);
  const [popup, setPopup] = React.useState(false);
  const [token, setToken] = React.useState(null);
  const [domain, setDomain] = React.useState(0);
  const [amount, setAmount] = React.useState(0);
  const [numOfMatches, setNumOfMatchs] = React.useState(0);
  const [num_of_matches, setNumOfMatches] = React.useState(0);
  const [galleryUsers, setGalleryUsers] = React.useState([]);

  const makePayment = async (cardId: string) => {
    try {
      const token = await AsyncStorage.getItem("token");
      const response = await axios.post(
        `${HOSTNAME}/api/v1/payment`,
        {
          amount: amount,
          num_of_matches: numOfMatches,
          card_id: cardId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response?.data;
      setNumOfMatches(data?.num_of_matches);
    } catch (error) {
      console.error(error);
      throw new Error("Failed to make payment");
    }
  };

  const initState = async () => {
    const payments = window?.Square?.payments(
      SQUARE_APP_ID,
      SQUARE_LOCATION_ID
    );
    const card = await payments?.card();
    await card?.attach("#card-container");

    const cardButton = document?.getElementById("card-button");
    cardButton?.addEventListener("click", async () => {
      const statusContainer = document?.getElementById(
        "payment-status-container"
      );

      try {
        const result = await card?.tokenize();
        if (result?.status === "OK") {
          await makePayment(result.token);
          statusContainer.innerHTML = "Payment Successful";
        } else {
          let errorMessage = `Tokenization failed with status: ${result.status}`;
          if (result?.errors) {
            errorMessage += ` and errors: ${JSON.stringify(result.errors)}`;
          }

          throw new Error(errorMessage);
        }
      } catch (e) {
        console.error(e);
        statusContainer.innerHTML = "Payment Failed";
      }
    });
  };

  const setMatch = (match: any) => {
    setAmount(match.amount);
    setNumOfMatchs(match.num_of_matches);
  };

  const getDomain = async () => {
    const domain = window.location.origin;
    setDomain(domain as any);
  };

  const fetchUserMatches = async () => {
    setLoading(true);
    try {
      const userId = await AsyncStorage.getItem("userId");
      setUserId(Number(userId));
      const token = await AsyncStorage.getItem("token");
      const { data } = await axios.get(`${HOSTNAME}/api/v1/user_matchs`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setMatches(data?.data ?? []);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const fetchNumOfMatches = async () => {
    try {
      const token = await AsyncStorage.getItem("token");
      const { data } = await axios.get(`${HOSTNAME}/api/v1/me`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setNumOfMatchs(data?.num_of_matches);
      setNumOfMatches(data?.num_of_matches);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMatchGallery = async () => {
    try {
      const token = await AsyncStorage.getItem("token");
      const { data } = await axios.get(
        `${HOSTNAME}/api/v1/find_matches_picker`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setGalleryUsers(data?.data || []);
    } catch (error) {
      console.error(error);
    }
  };

  const setUserMatch = async (user: any) => {
    try {
      const token = await AsyncStorage.getItem("token");
      await axios.post(
        `${HOSTNAME}/api/v1/user_matchs`,
        {
          user_id: user?.user_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPopup(false);
      fetchUserMatches();
    } catch (error) {
      console.error(error);
    }
  };

  React.useLayoutEffect(() => {
    const fetchToken = async () => {
      const token = await AsyncStorage.getItem("token");
      setToken(token as any);
    };

    fetchToken().catch(console.error);
    setRoute(route?.name);
    // fetchSubscription().then((sub) => {
    //   if (!sub) {
    //     navigation.navigate("Membership Setup");
    //   }
    // });
  }, [setRoute, route?.name]);

  React.useEffect(() => {
    getDomain().catch(console.error);
    fetchNumOfMatches().catch(console.error);
    fetchUserMatches();
  }, []);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: themes === "light" ? white : darkGrey,
      })}
    >
      <Scroll>
        <View style={styles.row}>
          {/* <TouchableOpacity
            onPress={() => {
              initState();
              setPopup(true);
            }}
          >
            <Text style={styles.icon}>+</Text>
          </TouchableOpacity> */}
          <View></View>
          <Text category="h1">Matches</Text>
          <TouchableOpacity onPress={fetchUserMatches}>
            <Text style={styles.icon}>⟳</Text>
          </TouchableOpacity>
        </View>
        {loading && <Loader />}
        {!loading && !matches.length && (
          <>
            <Text>No matches found. Please try again later.</Text>
            <Text>We will match you with someone every 24 hours.</Text>
            <Text>
              As we grow more users, we will match you with better selections
              personalized for you.
            </Text>
          </>
        )}
        <List
          style={styles.list}
          data={matches}
          renderItem={({ item, key }: any) => (
            <Card key={key} style={styles.card}>
              <View style={styles.row}>
                <Link
                  to={`/profile/${
                    item?.user_id === userId
                      ? item?.match?.user_id
                      : item?.user_id
                  }?user_match_id=${
                    item?.user_id === userId
                      ? item?.match?.user_id
                      : item?.user_id
                  }`}
                >
                  <Image
                    style={styles.image}
                    source={{ uri: item?.match_photos[0]?.photo_url }}
                  />
                </Link>
                <Link
                  to={`/profile/${
                    item?.user_id === userId
                      ? item?.match?.user_id
                      : item?.user_id
                  }?user_match_id=${
                    item?.user_id === userId
                      ? item?.match?.user_id
                      : item?.user_id
                  }`}
                >
                  <View style={styles.column}>
                    <Text>{item?.match_profile?.first_name}</Text>
                    <Text>{item?.match_profile?.occupation}</Text>
                    <Text>{item?.match_profile?.about_me}</Text>
                  </View>
                </Link>
                {item?.is_accepted && (
                  <TouchableOpacity
                    onPress={() =>
                      navigation.navigate("Calendar", {
                        userId:
                          userId === item?.user_id
                            ? item?.match?.user_id
                            : item?.user_id,
                      })
                    }
                    style={styles.column}
                  >
                    <Calendar style={styles.icon} />
                  </TouchableOpacity>
                )}
                <Link
                  to={`/profile/${
                    item?.user_id === userId
                      ? item?.match?.user_id
                      : item?.user_id
                  }?user_match_id=${
                    item?.user_id === userId
                      ? item?.match?.user_id
                      : item?.user_id
                  }`}
                >
                  <View style={styles.column}>
                    {item?.is_accepted && (
                      <Accept style={styles.icon} fill={"green"} />
                    )}
                    {item?.is_rejected && (
                      <Deny style={styles.icon} fill={"red"} />
                    )}
                    {item?.is_pending && (
                      <Pending style={styles.icon} fill={"grey"} />
                    )}
                  </View>
                </Link>
                <Options
                  navigation={navigation}
                  route={route}
                  matchId={item?.match_profile?.user_id}
                />
              </View>
            </Card>
          )}
        />
      </Scroll>
      <Modal
        visible={popup}
        backdropStyle={styles.backdrop}
        onBackdropPress={() => {
          setPopup(false);
        }}
        onShow={fetchMatchGallery}
      >
        <Card>
          {!num_of_matches ? (
            <>
              <Text>Select Matches</Text>
              {match.map((match, key) => (
                <Card
                  onPress={() => {
                    setMatch(match);
                  }}
                  style={match?.amount === amount ? styles.outline : {}}
                  key={key}
                >
                  <Text>Price: {match?.amount?.toFixed(2)}</Text>
                  <Text>Number of Matches: {match?.num_of_matches}</Text>
                </Card>
              ))}
              <div id="payment-form">
                <div id="payment-status-container"></div>
                <div id="card-container"></div>
                <button id="card-button" type="button">
                  Pay
                </button>
              </div>
            </>
          ) : (
            <>
              <Text>
                You have {num_of_matches} matches left. Please select a match
                package.
              </Text>
              <Card>
                <List
                  data={galleryUsers}
                  renderItem={({ item, key }: any) => (
                    <Card
                      key={key}
                      style={styles.card}
                      onPress={() => setUserMatch(item)}
                    >
                      <View style={styles.row}>
                        <Image
                          style={styles.image}
                          source={{ uri: item?.photo_url }}
                        />
                      </View>
                    </Card>
                  )}
                />
              </Card>
            </>
          )}
        </Card>
      </Modal>
    </Layout>
  );
};

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  card: {
    margin: 10,
    width: "95%",
  },
  column: {
    flexDirection: "column",
  },
  container: {
    alignItems: "center",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
  },
  fullscreen: {
    height: "100%",
    width: "100%",
  },
  icon: {
    fontSize: 32,
    height: 32,
    width: 32,
  },
  image: {
    height: 100,
    width: 100,
  },
  list: {
    flexDirection: "column",
    width: "100%",
  },
  outline: {
    borderColor: primary,
    borderWidth: 5,
  },
  row: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 50,
    width: "100%",
  },
});

export default Dashboard;
