import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Layout, Card, CheckBox, Text } from "@ui-kitten/components";
import axios from "axios";
import { StyleSheet } from "react-native";

import { HOSTNAME } from "app/utils/ajax";
import {
  AuthContext,
  ThemeContext,
  type ThemeContextProps,
  type AuthContextProps,
} from "app/utils/context";

const LIST = [
  "High School",
  "In College",
  "Associate's Degree",
  "Bachelor's Degree",
  "Master's Degree",
  "Doctorate's Degree",
];

const EducationLevel: React.FC<any> = ({ navigation, route, edit = false }) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;

  const [education_level, setEducationLevel]: any = React.useState([]);

  const setChecked = (nextChange: boolean, item: any) => {
    const array = (education_level || []).slice(0);
    if (nextChange) {
      array.push(item);
    } else {
      const index = array.findIndex((e: any) => e === item);
      array.splice(index, 1);
    }
    setEducationLevel(array);
    setEducationLevels(array);
  };

  const fetchProfile = async () => {
    try {
      const token = await AsyncStorage.getItem("token");
      const response = await axios.get(
        `${HOSTNAME}/api/v1/user_dating_preferences`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = response?.data;
      setEducationLevel(res?.data[0]?.education_level?.split(","));
    } catch (error) {
      console.error(error);
    }
  };

  const setEducationLevels = async (array: any) => {
    try {
      const token = await AsyncStorage.getItem("token");
      const response = await axios.put(
        `${HOSTNAME}/api/v1/user_dating_preferences`,
        {
          education_level: array?.join(","),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = response?.data;
      setEducationLevel(res?.data[0]?.education_level?.split(","));
    } catch (error) {
      console.error(error);
    }
  };

  React.useLayoutEffect(() => {
    setRoute(route?.name);
  }, [setRoute, route?.name]);

  React.useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <Layout>
      <Card>
        <Text>Education Level</Text>
        {LIST.map((item, index) => (
          <CheckBox
            checked={!!education_level?.find((e: any) => e === item)}
            onChange={(nextChecked) => {
              setChecked(nextChecked, item);
            }}
            key={index}
          >
            {item}
          </CheckBox>
        ))}
      </Card>
    </Layout>
  );
};

const styles = StyleSheet.create({
  input: {
    width: "100%",
  },
});

export default EducationLevel;
